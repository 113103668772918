// Basic
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class PlatformService {
	constructor(private readonly platform: Platform) {}

	public isBrowser() {
		return !this.isMobile();
	}

	public isMobile() {
		return this.platform.is('ios') || this.platform.is('android');
	}

	public isAndroid() {
		return this.platform.is('android');
	}

	public isIOS() {
		return this.platform.is('ios');
	}

	public isTablet() {
		return this.platform.is('tablet');
	}
}
