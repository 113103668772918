import { createAction, props } from '@ngrx/store';

export enum DocumentActionTypes {
	requestDocument = '[API-DOCUMENT] REQUEST DOCUMENT BY MACHINE VERSION',
	requestDocumentFromDB = '[DB-DOCUMENT] REQUEST DOCUMENT BY MACHINE VERSION',
	requestDocumentSucess = '[API-DOCUMENT] SUCESS DOCUMENT BY MACHINE VERSION'
}

export const requestDocument = createAction(
	DocumentActionTypes.requestDocument,
	props<{
		version: any;
	}>()
);
export const requestDocumentFromDB = createAction(
	DocumentActionTypes.requestDocumentFromDB,
	props<{
		version: any;
	}>()
);
export const requestDocumentSucess = createAction(
	DocumentActionTypes.requestDocumentSucess,
	props<{
		result: any;
	}>()
);
