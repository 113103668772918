export const MACHINE_CONSTANTS = {
	MACHINE_URI: 'machine',
	SERIALNUMBER: 'Serial Number',
	PRODUCT_ID: 'Manufacturer product number',
	PRODUCT_ORDER_SUFFIX: 'Manufacturer product order suffix',
	PRODUCT_DESIGNATION: 'Manufacturer product designation',
	MADE_IN: { NAME: 'Made in', REF: 'ECLASS:0173-1#02-AAO995#001' },
	OPERATING_POINTS: 'operating points',
	MODEL: [
		{ key: 'default', model: 'DEFAULT', image: 'machine-placeholder.jpg' },
		{ key: 'simoticssd', model: 'SIMOTICS SD', image: 'simotics-sd.jpg' },
		{ key: 'simoticsgp', model: 'SIMOTICS GP', image: 'simotics-sd.jpg' },
		{ key: 'simoticsxp', model: 'SIMOTICS XP', image: 'simotics-xp.jpg' },
		{ key: 'simoticshvm', model: 'SIMOTICS HV M', image: 'simotics-hv-m.jpg' },
		{ key: 'simoticshvc', model: 'SIMOTICS HV C', image: 'simotics-hv-c.jpg' },
		{ key: 'sinamicsg150', model: 'SINAMICS G150', image: 'sinamics-g150.jpg' },
		{
			key: 'sinamicsgh180',
			model: 'SINAMICS GH180',
			image: 'sinamics-gh180-perfect-harmony.jpg'
		},
		{
			key: 'sinamicsgh180',
			model: 'SINAMICS GH180 PERFECT HARMONY',
			image: 'sinamics-gh180-perfect-harmony.jpg'
		}
	]
};
