import { Injectable } from '@angular/core';
import { RecentlyDatabaseService } from '../db/recently-database.service';
import { Recently, SynchroModel } from '@modules/recently/core/model/recently';
import { FavoriteDatabaseService } from '@modules/favorites/infrastructure/db/favorite-database.service';

@Injectable({ providedIn: 'root' })
export class RecentlyService {
	constructor(
		private recentlyDB: RecentlyDatabaseService,
		private favoriteDB: FavoriteDatabaseService,
	) {}

	public getAllRecently(): Promise<any> {
		return this.recentlyDB.getAll();
	}

	public remove(serialNumber: string): Promise<any> {
		return this.recentlyDB.remove(serialNumber);
	}

	public setRecently(recently: Recently): Promise<any> {
		return this.recentlyDB.set(recently.serialNumber, recently);
	}

	public async updateRecentlyStatus(
		serialNumber: string,
		synchroStatus: SynchroModel.SynchroStatus
	): Promise<any> {
		const recently = await this.recentlyDB.get(serialNumber);
		return this.recentlyDB.set(serialNumber, { ...recently, synchroStatus });
	}

	public async isRecentlyBoormarked(recently: Recently): Promise<boolean> {
		const bookmark = await this.favoriteDB.get(recently.serialNumber);
		return !!bookmark;
	}
}
