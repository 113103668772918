// Basic
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { share } from 'rxjs/operators';

// Ionic Native
import { Network } from '@capacitor/network';

/**
 * Network status service to know if the application has access to internet or not.
 */
@Injectable({ providedIn: 'root' })
export class NetworkStatus {
	/**
	 * Observer with the value of the current network status
	 */
	private onlineSubject: BehaviorSubject<boolean>;

	/**
	 * the state of the actual network
	 */
	private currentNetworkStateCordova: boolean;

	/**
	 * Constructor where we import all needed in the service.
	 * @param network plugin to know if the device is connected
	 * @param platform Used to get information about your current device.
	 */
	constructor(private platform: Platform) {
		// Dont care about the first state of the app, lets suppose that is online
		this.onlineSubject = new BehaviorSubject(true);
		this.platform.ready().then(() => {
			// We need to ensure that the plugins have been loaded (firefox issue)
			if (this.platform.is('capacitor')) {
				let networkType = '';
				Network.getStatus().then(function(result) {
					networkType = result.connectionType;
				});

				if (networkType === 'none') {
					this.onlineSubject.next(false);
					this.currentNetworkStateCordova = false;
				} else {
					this.onlineSubject.next(true);
					this.currentNetworkStateCordova = true;
				}

				Network.addListener('networkStatusChange', status => {
					if(status.connected){
						this.onlineSubject.next(true);
						this.currentNetworkStateCordova = true;
					}else{
						this.onlineSubject.next(false);
					this.currentNetworkStateCordova = false;
					}
				});
			} else {
				this.onlineSubject.next(navigator.onLine);
				this._onOnline(() => this.onlineSubject.next(true));
				this._onOffline(() => this.onlineSubject.next(false));
			}
		});
	}

	/**
	 * whether the network is considered online or not.
	 */
	public isOnline(): boolean {
		if (this.platform.is('capacitor')) {
			return this.currentNetworkStateCordova;
		} else {
			return navigator.onLine;
		}
	}

	/**
	 * whether the network is considered offline or not.
	 */
	public isOffline(): boolean {
		if (this.platform.is('capacitor')) {
			return !this.currentNetworkStateCordova;
		} else {
			return !navigator.onLine;
		}
	}

	/**
	 * Returns an observable to know the changes on the network.
	 */
	public onlineObserver(): Observable<boolean> {
		return this.onlineSubject.asObservable().pipe(share());
	}

	/**
	 * Register a new callback to be executed when network turns up.
	 * @param cb callback
	 */
	private _onOnline(cb: any) {
		const listener = cb;
		window.addEventListener('online', listener, false);
		return function deregisterOnOnline() {
			window.removeEventListener('online', listener);
		};
	}

	/**
	 * Register a new callback to be executed when network turns down.
	 * @param cb callback
	 */
	private _onOffline(cb: any) {
		const listener = cb;
		window.addEventListener('offline', listener, false);
		return function deregisterOnOffline() {
			window.removeEventListener('offline', listener);
		};
	}
}
