import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import {
	MachineActionTypes,
	requestMachineVersionHistory,
	noOpAction,
	cacheOfflineMachine,
	deleteOfflineMachineFromDB,
	deleteMachineLocationFromDB
} from '../machine.actions';
import { MachineService } from '../../infrastructure/service/machine.service';
import { from } from 'rxjs';
import { updateRecentlyStatus } from '@modules/recently/actions';
import { SynchroModel } from '@modules/recently/core/model/recently';
import { RecentlyService } from '@modules/recently/infrastructure/service/recently.service';
import { OfflineMachine } from '../../core/model/machine';

@Injectable()
export class OfflineMachineEffects {
	checkOfflineMachines$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MachineActionTypes.checkOfflineMachines),
			mergeMap(() =>
				from(this.machineService.getAllOfflineMachines()).pipe(
					mergeMap((offlineMachines: OfflineMachine[]) => {
						return offlineMachines.map(offlineMachine =>
							cacheOfflineMachine({
								serialnumber: offlineMachine.serialnumber,
								manufacturerproductnumber:
									offlineMachine.manufacturerproductnumber
							})
						);
					})
				)
			)
		);
	});

	saveOfflineMachineIntoDB$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MachineActionTypes.saveOfflineMachineIntoDB),
			mergeMap((action: any) =>
				from(
					this.machineService.setOfflineMachine(
						action.serialnumber,
						action.machine
					)
				).pipe(map(() => noOpAction({})))
			)
		);
	});

	deleteOfflineMachineIntoDB$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MachineActionTypes.deleteOfflineMachineFromDB),
			mergeMap((action: any) =>
				from(
					this.machineService.removeOfflineMachine(action.serialnumber)
				).pipe(map(() => noOpAction({})))
			)
		);
	});

	cacheOfflineMachine$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MachineActionTypes.cacheOfflineMachine),
			mergeMap((action: any) =>
				this.machineService
					.getLatestVersion(
						action.serialnumber,
						action.manufacturerproductnumber
					)
					.pipe(
						mergeMap(() => [
							requestMachineVersionHistory({
								serialnumber: action.serialnumber,
								manufacturerproductnumber: action.manufacturerproductnumber
							}),
							this.updateRecentlyStatus(action.serialnumber, SynchroModel.OK),
							deleteOfflineMachineFromDB({
								serialnumber: action.serialnumber
							})
						]),
						catchError(() => {
							return from([
								this.updateRecentlyStatus(
									action.serialnumber,
									SynchroModel.NOT_FOUND
								),
								deleteOfflineMachineFromDB({
									serialnumber: action.serialnumber
								}),
								deleteMachineLocationFromDB({
									serialnumber: action.serialnumber
								})
							]);
						})
					)
			)
		);
	});

	private updateRecentlyStatus(
		serialNumber: string,
		synchroStatus: SynchroModel.SynchroStatus
	) {
		this.recentlyService.updateRecentlyStatus(serialNumber, synchroStatus);

		return updateRecentlyStatus({
			serialNumber: serialNumber,
			synchroStatus
		});
	}

	constructor(
		private recentlyService: RecentlyService,
		private actions$: Actions,
		private machineService: MachineService
	) {}
}
