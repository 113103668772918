import { Injectable } from '@angular/core';
import { Favorite } from '@modules/favorites/core/model/favorite';
import { FavoriteDatabaseService } from '../db/favorite-database.service';

@Injectable({ providedIn: 'root' })
export class FavoritesService {
	constructor(
		private favoriteDB: FavoriteDatabaseService,
	) {}

	public getAllFavorites(): Promise<any> {
		return this.favoriteDB.getAll();
	}

	public getFavorite(serialNumber: string): Promise<any> {
		return this.favoriteDB.get(serialNumber).catch(() => {
			return;
		});
	}

	public saveFavorite(favorite: Favorite) {
		return this.favoriteDB.set(favorite.serialNumber, favorite);
	}

	public removeFavorite(serialNumber: string): Promise<any> {
		return this.favoriteDB.remove(serialNumber);
	}
}
