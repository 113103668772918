import { createReducer, Action, on } from '@ngrx/store';
import * as RecentlyActions from './recently.actions';
import { Recently } from '../core/model/recently';

export const recentlyFeatureKey = 'recently';

export interface RecentlyState {
	recently: Recently[];
}

export const initialState: RecentlyState = {
	recently: []
};

const recentlyReducer = createReducer(
	initialState,
	on(RecentlyActions.saveRecently, (state, { recently }) => {
		const recentlyState = [...state.recently];

		let existingRecently = recentlyState.find(
			recentlyItem => recentlyItem.serialNumber === recently.serialNumber
		);

		if (!existingRecently) {
			recentlyState.push(recently);
		} else {
			recentlyState.splice(recentlyState.indexOf(existingRecently), 1);
			recentlyState.push(recently);
		}

		return {
			recently: recentlyState.sort((recentlyA, recentlyB) => {
				const recentlyATime = new Date(recentlyA.lastSearched).getTime();
				const recentlyBTime = new Date(recentlyB.lastSearched).getTime();

				return recentlyBTime - recentlyATime;
			})
		};
	}),
	on(RecentlyActions.removeRecently, (state, { recently }) => {
		const favorites = [...state.recently];
		const filteredRecents = favorites.filter(
			item => item.serialNumber !== recently.serialNumber
		);
		return {
			recently: filteredRecents
		};
	}),
	on(
		RecentlyActions.updateRecentlyStatus,
		(state, { serialNumber, synchroStatus }) => {
			return {
				recently: state.recently.map(recently =>
					recently.serialNumber === serialNumber
						? {
								...recently,
								synchroStatus: synchroStatus
						  }
						: recently
				)
			};
		}
	),
	on(
		RecentlyActions.updateRecentlyIsBookmarked,
		(state, { serialNumber, isBookmarked }) => {
			return {
				recently: state.recently.map(recently =>
					recently.serialNumber === serialNumber
						? {
								...recently,
								isBookmarked
						  }
						: recently
				)
			};
		}
	),
	on(RecentlyActions.saveRecents, (state, { recently }) => {
		return {
			recently
		};
	})
);

export function reducer(state: RecentlyState, action: Action) {
	return recentlyReducer(state, action);
}
