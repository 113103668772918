import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Device } from '@capacitor/device';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { ApplicationDataStore } from '@providers/stores/application-data-store.service';

@Injectable({ providedIn: 'root' })
export class UniqueDeviceIDService {
	private uniqueDeviceId: string;

	constructor(
		private platform: Platform,
		private appDataStore: ApplicationDataStore,
	) {}

	public async init(): Promise<any> {
		return await new Promise<any>((resolve, reject) => {
			if (this.uniqueDeviceId) {
				return resolve(undefined);
			} else {
				const isMobile = this.platform.is('android') || this.platform.is('ios');

				if (isMobile) {
					try {
						Device.getId().then(function(result) {
							this.uniqueDeviceId = result.identifier;
						});
						if (this.uniqueDeviceId) {
							return resolve(undefined);
						} else {
							return reject();
						}
					} catch (error) {
						return reject(error);
					}
				} else {
					this.appDataStore.getUniqueDeviceId().then(response => {
							// We use the stored X-Guid
							this.uniqueDeviceId = response;
							resolve(undefined);
						}, () => {
							const fpPromise = FingerprintJS.load();

							fpPromise.then(fp => fp.get()).then(result => {
								this.appDataStore.setUniqueDeviceId(result.visitorId).then(() => {
									this.uniqueDeviceId = result.visitorId;
									return resolve(undefined);
								}, () => {
									return reject();
								});
							});
						}
					);
				}
			}
		});
	}

	public getUniqueDeviceId(): string {
		return this.uniqueDeviceId;
	}
}
