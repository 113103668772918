// Basic
import { Injectable } from '@angular/core';

// Services
import { ApiGenericProvider } from '@core/core.module';
/**
 * Constants speficying Url to make calls to.
 */
import { APPLICATION_CONSTANTS } from '@core/constants/application-data.constants';

/**
 * Service that returns machine information from server.
 */
@Injectable({ providedIn: 'root' })
export class ApiMachineProvider extends ApiGenericProvider {
	private uriIssuerDocument: string;
	private uriIssuerDocumentRoot: string;
	private uriSignedCookieDocument: string;
	/**
	 * ApiMachineProvider constructor
	 * @param http Anngular's http service to make calls against a server.
	 * @param geolocationService Geolocation service to get the current location.
	 * @param uniqueDeviceIDService Service to get the devices' unique ID.
	 * @param loggerService Service to log.
	 * @param permissionStore Store for controlling application permissions.
	 */
	constructor() {
		super(`machine/`);
		this.uriIssuerDocument = 'document/issuer';
		this.uriIssuerDocumentRoot = 'machine';
		this.uriSignedCookieDocument = 'signedcookie';
	}

	/**
	 * Method to get the latest version of a given machine
	 * @param machineSerialNumber Machine's serial number.
	 * @param isBarcode barcode or manual input flag
	 */
	public getLatestVersion(
		machineSerialNumber: string,
		machineProductNumber: string,
		isBarcode?: boolean
	): Promise<any> {
		return this._getMachineVersion(
			machineSerialNumber,
			machineProductNumber,
			'latest',
			isBarcode
		);
	}

	/**
	 * Method to get the first version of a given machine
	 * @param machineSerialNumber Machine's serial number.
	 * @param isBarcode barcode or manual input flag
	 */
	public getFirstVersion(
		machineSerialNumber: string,
		machineProductNumber: string,
		isBarcode?: boolean
	): Promise<any> {
		return this._getMachineVersion(
			machineSerialNumber,
			machineProductNumber,
			'first',
			isBarcode
		);
	}

	/**
	 * Method to get a specific version of a given machine
	 * @param machineSerialNumber Machine's serial number.
	 * @param isBarcode barcode or manual input flag
	 */
	public getMachineVersion(
		machineSerialNumber: string,
		machineProductNumber: string,
		version: string,
		isBarcode?: boolean
	): Promise<any> {
		return this._getMachineVersion(
			machineSerialNumber,
			machineProductNumber,
			version,
			isBarcode
		);
	}

	/**
	 * Function that gets information about a machine from its serial number.
	 * @param machineSerialNumber Machine's serial number.
	 * @param isBarcode barcode or manual input flag
	 * @return Object list with all machine versions information.
	 */
	private _getMachineVersion(
		machineSerialNumber: string,
		machineProductNumber: string,
		version: string,
		isBarcode?: boolean
	): Promise<any> {
		this.updateUserInfoValues(isBarcode);
		const encodeVersion = encodeURIComponent(version);
		const uri = `/version/${encodeVersion}`;
		return this.performRequestMachineResource(
			machineSerialNumber,
			machineProductNumber,
			uri
		);
	}

	/**
	 * Function that gets the version history of a given machine.
	 * @param machineSerialNumber Machine's serial number.
	 */
	public getMachineVersionHistory(
		machineSerialNumber: string,
		machineProductNumber: string
	): Promise<any> {
		return this.performRequestMachineResource(
			machineSerialNumber,
			machineProductNumber,
			'/versionhistory'
		);
	}
	private performRequestMachineResource(
		machineSerialNumber: string,
		machineProductNumber: string,
		uri: string
	) {
		const key = `${machineSerialNumber}${APPLICATION_CONSTANTS.SEPARATION_CHARACTER_SERIALNUMBER_PRODUCTNUMBER}${machineProductNumber}`;
		const enchanhedUri = `${encodeURIComponent(key)}${uri}`;
		return this.get(enchanhedUri);
	}
	/**
	 * Function that returns the signed url to open a private document.
	 * @param machineSerialNumber Machine's serial number.
	 * @param machineProductNumber Machine's product number.
	 * @param documentId document id that is going to be opened.
	 */
	public getSignedUrlDocument(
		machineSerialNumber: string,
		machineProductNumber: string,
		documentId: string
	): Promise<any> {
		const key = this.getKey(machineSerialNumber, machineProductNumber);
		const uri = `${key}/${this.uriIssuerDocument}/${documentId}/signedurl`;
		return this.get(uri);
	}

	/**
	 * Function that returns the signed cookie to open a private document(html documents).
	 * @param machineSerialNumber Machine's serial number.
	 * @param machineProductNumber Machine's product number.
	 * @param documentId document id that is going to be opened.
	 */
	public getCookiesDocument(
		machineSerialNumber: string,
		machineProductNumber: string,
		documentId: string
	): Promise<any> {
		const key = this.getKey(machineSerialNumber, machineProductNumber);
		const uri = `${key}/${this.uriIssuerDocument}/${documentId}/${this.uriSignedCookieDocument}`;
		return this.get(uri);
	}

	/**
	 * Function that returns the urls for the opening of a document adding the cookie as queryparam.
	 * @param machineSerialNumber Machine's serial number.
	 * @param machineProductNumber Machine's product number.
	 * @param documentId document id that is going to be opened.
	 * @param signedCookies mandatory for the opening of the document
	 */
	public getUrlOpenDocument(
		machineSerialNumber: string,
		machineProductNumber: string,
		documentId: string,
		signedCookies: string
	) {
		const key = this.getKey(machineSerialNumber, machineProductNumber);
        let url = `${this.url}${this.uri}${encodeURIComponent(key)}/${this.uriIssuerDocument}/${documentId}/${this.uriSignedCookieDocument}/open`;
		url += `?signedcookie=${signedCookies}`;
		return url;
	}

	/**
	 * Returns the path params with the concatenation of serialnumber and product number
	 * @param machineSerialNumber Machine's serial number.
	 * @param machineProductNumber Machine's product number.
	 */
	private getKey(machineSerialNumber: string, machineProductNumber: string) {
		return `${machineSerialNumber}${APPLICATION_CONSTANTS.SEPARATION_CHARACTER_SERIALNUMBER_PRODUCTNUMBER}${machineProductNumber}`;
	}
}
