import { StorageConfig } from '@ionic/storage';
import { DB_NAME } from '@core/constants/storage.constants';

export const recentlyConfig = {
	databaseConfig: {
		name: `${DB_NAME.NAME}_v${DB_NAME.VERSION}.${DB_NAME.SUFFIX}`,
		storeName: '_recently',
		driverOrder: ['indexeddb', 'sqlite', 'websql']
	} as StorageConfig,
	encryption: true
};
