// Basic
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

// Services
import { StorageService } from '@providers/services/storage.service';

// Model
import { ApiTranslationModel } from '@core/model/api/api-translation.model';

// Config
import { apiTranslationConfig } from '@config/config.module';

/**
 * Api translation database service to access to the persistance data.
 */
@Injectable({ providedIn: 'root' })
export class ApiTranslationDatabaseService {
	/**
	 * Api translations database instance
	 */
	private apiTranslationDatabase: Storage;

	/**
	 * Construtor where we import all needed in the service.
	 * @param storageService instance of the storage general service.
	 */
	constructor(private storageService: StorageService) {
		this.apiTranslationDatabase = this.storageService.create(
			apiTranslationConfig.databaseConfig
		);
	}

	/**
	 * Method to get a translation stored inside database.
	 * @param key Primary key to get the value inside the database.
	 */
	public get(key: string): Promise<any> {
		return this.storageService.get(
			this.apiTranslationDatabase,
			apiTranslationConfig.encryption,
			key
		);
	}

	/**
	 * Method to set a translation inside database.
	 * @param key Primary key to be stored inside the database.
	 * @param value Value to be stored inside the database
	 */
	public set(key: string, value: ApiTranslationModel): Promise<any> {
		return this.storageService.set(
			this.apiTranslationDatabase,
			apiTranslationConfig.encryption,
			key,
			value
		);
	}

	/**
	 * Method to get all the translations from the database.
	 */
	public getAll(): Promise<any> {
		return this.storageService.getAll(
			this.apiTranslationDatabase,
			apiTranslationConfig.encryption
		);
	}

	/**
	 * Delete the translation of the key provided by parameter.
	 * @param key Primary key to delete the value.
	 */
	public remove(key: string) {
		return this.storageService.remove(this.apiTranslationDatabase, key);
	}

	/**
	 * Delete all entries on the translations database.
	 */
	public removeAll() {
		return this.storageService.removeAll(this.apiTranslationDatabase);
	}
}
