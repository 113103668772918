// Basic
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

// Ionic Native
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';

// Api Services
import { PermissionDatabaseService } from '@providers/db/permission-database.service';

// Stores
import { ApplicationDataStore } from '@providers/stores/application-data-store.service';

// Services
import { UniqueDeviceIDService } from '@providers/services/unique-device-id.service';
import { AlertService } from '@providers/services/alert.service';
import { ToastService } from '@providers/services/toast.service';
import { TranslateService } from '@providers/services/translate.service';
import { LoggerService } from '@providers/services/logger.service';

// Constants
import { PERMISSION_CONSTANTS } from '@core/constants/permission.constants';
import { first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { PlatformService } from '@providers/services/platform.service';

/**
 * Store to handle the application permissions.
 */
@Injectable({ providedIn: 'root' })
export class PermissionStore {
	private isGeolocationInitialized: boolean;
	private isUniqueDeviceIdInitialized: boolean;
	private areBothGeolocAndGuidPermitted: boolean;

	public privacyPermissionStatus = new BehaviorSubject<boolean>(false);
	public sharedPrivacyPermission = this.privacyPermissionStatus.asObservable();

	constructor(
		private readonly platform: Platform,
		private readonly diagnostic: Diagnostic,
		private readonly toastService: ToastService,
		private readonly alertService: AlertService,
		private readonly loggerService: LoggerService,
		private readonly appDataStore: ApplicationDataStore,
		private readonly translateService: TranslateService,
		private readonly permissionDB: PermissionDatabaseService,
		private readonly platformService: PlatformService,
		private readonly uniqueDeviceIdService: UniqueDeviceIDService
	) {
		this.permissionDB
			.get(PERMISSION_CONSTANTS.APP_PERMISSIONS.GEOLOC_AND_GUID)
			.then(
				isPermitted => {
					// If stored permission is true, autoinitialize the services
					if (isPermitted) {
						// Create the promise array for both initializations
						const promiseArray: Promise<any>[] = [];
						// Depending on the platform, we will check permissions or not, but initialization must happen anyway at the right moment
						if (this.platform.is('android')) {
							// Check if current permissions are aligned with this value.
							this.diagnostic
								.getPermissionsAuthorizationStatus([
									this.diagnostic.permission.ACCESS_COARSE_LOCATION,
									this.diagnostic.permission.ACCESS_FINE_LOCATION,
									this.diagnostic.permission.READ_PHONE_STATE
								])
								.then(
									permissionsStatus => {
										if (
											permissionsStatus.ACCESS_COARSE_LOCATION ===
												this.diagnostic.permissionStatus.GRANTED &&
											permissionsStatus.ACCESS_FINE_LOCATION ===
												this.diagnostic.permissionStatus.GRANTED &&
											permissionsStatus.READ_PHONE_STATE ===
												this.diagnostic.permissionStatus.GRANTED
										) {
											// Create the promise array for both initiatilizations to be done before putting the areBothGeolocAndGuidPermitted flag on
											promiseArray.push(this.initiateGuidService());
											this.setWhenConstructorPromisesDone(promiseArray);
										} else {
											// If not granted all permissions then update local storage permission to false
											this.fixUnalignedGeolocAndGuidPermissions();
										}
									},
									error => {
										this.loggerService.error(
											this,
											`Something went wrong while checking the current status for android needed permissions for geolocation and guid. `,
											error
										);
										this.initializePermissionsFalse();
									}
								);
						} else if (this.platform.is('ios')) {
							this.diagnostic.isLocationAuthorized().then(
								authorized => {
									if (authorized) {
										promiseArray.push(this.initiateGuidService());
									} else {
										this.fixUnalignedGeolocAndGuidPermissions();
									}
									this.setWhenConstructorPromisesDone(promiseArray);
								},
								error => {
									this.loggerService.error(
										this,
										`Something went wrong while checking current status for ios needed permissions for geolocation. `,
										error
									);
									this.initializePermissionsFalse();
								}
							);
						} else {
							// Create the promise array for both initiatilizations to be done before putting the areBothGeolocAndGuidPermitted flag on
							promiseArray.push(this.initiateGuidService());
							this.setWhenConstructorPromisesDone(promiseArray);
						}
					} else {
						this.initializePermissionsFalse();
					}
				},
				error => {
					this.loggerService.info(
						this,
						`Stored permissions for GEOLOC_AND_GUID not initialized yet. `,
						error
					);
					this.initializePermissionsFalse();
				}
			);
	}

	/**
	 * Mthod to update local storage permission to false in case it is not aligned with real device permissions for the app
	 */
	private fixUnalignedGeolocAndGuidPermissions(): void {
		this.permissionDB
			.set(PERMISSION_CONSTANTS.APP_PERMISSIONS.GEOLOC_AND_GUID, false)
			.then(
				() => {
					this.loggerService.info(
						this,
						`Stored permissions were not aligned with device app permissions; stored permissions are now set to FALSE.`
					);
					this.initializePermissionsFalse();
				},
				error => {
					this.loggerService.error(
						this,
						`permissionDB SET FAIL when fixing unaligned stored permissions. `,
						error
					);
					this.initializePermissionsFalse();
				}
			);
	}

	/**
	 * When initializations are done, we set the db value up to be done before putting the areBothGeolocAndGuidPermitted flag on
	 */
	private setWhenConstructorPromisesDone(promiseArray: Promise<any>[]): void {
		Promise.all(promiseArray).then(
			() => (this.areBothGeolocAndGuidPermitted = true),
			error => {
				this.loggerService.error(
					this,
					`Something went wrong while initializing geoloc & guid services. `,
					error
				);
				this.initializePermissionsFalse();
			}
		);
	}

	/**
	 * Just a method to simplify code that sets both geoloc an guid initialization flags to false
	 */
	private initializePermissionsFalse(): void {
		this.isGeolocationInitialized = false;
		this.isUniqueDeviceIdInitialized = false;
		this.areBothGeolocAndGuidPermitted = false;
	}

	/**
	 * Return if both geolocation and unique device id have been initialized (that is permission true).
	 */
	public getGeolocAndGuidSettings(): boolean {
		return this.areBothGeolocAndGuidPermitted;
	}

	public async statusPermissionLocation(): Promise<boolean> {
		if (this.platform.is('android')) {
			// Check if current permissions are aligned with this value.
			const permissionsStatus =
				await this.diagnostic.getPermissionsAuthorizationStatus([
					this.diagnostic.permission.ACCESS_COARSE_LOCATION,
					this.diagnostic.permission.ACCESS_FINE_LOCATION,
					this.diagnostic.permission.READ_PHONE_STATE
				]);

			if (
				permissionsStatus.ACCESS_COARSE_LOCATION ===
					this.diagnostic.permissionStatus.GRANTED &&
				permissionsStatus.ACCESS_FINE_LOCATION ===
					this.diagnostic.permissionStatus.GRANTED &&
				permissionsStatus.READ_PHONE_STATE ===
					this.diagnostic.permissionStatus.GRANTED
			) {
				return true;
			} else {
				return false;
			}
		} else if (this.platform.is('ios')) {
			const permissionStatus = await this.diagnostic.isLocationAuthorized();
			if (permissionStatus) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	/**
	 * Attempt to update the geolocation and guid permissions to the given permissionValue value.
	 * @param permissionValue set the combined permission to this value, true or false.
	 */
	public updateGeolocAndGuidPermission(permissionValue: boolean): Promise<any> {
		const promise = new Promise<object[]>(async (resolve, reject) => {
			if (permissionValue) {
				if (this.isGeolocationInitialized && this.isUniqueDeviceIdInitialized) {
					this.nextPrivacyStatus(true);
					this.permissionDB
						.set(PERMISSION_CONSTANTS.APP_PERMISSIONS.GEOLOC_AND_GUID, true)
						.then(
							() => {
								this.areBothGeolocAndGuidPermitted = true;
								this.appDataStore.resetPrivacyPermission();
								resolve(undefined);
							},
							error => {
								this.loggerService.error(
									this,
									`permissionDB SET FAIL for: ${
										PERMISSION_CONSTANTS.APP_PERMISSIONS.GEOLOC_AND_GUID
									} ${true}. `,
									error
								);
								reject(error);
							}
						);
				} else {
					// Create the promise array for both initializations
					const promiseArray: Promise<any>[] = [];
					// Check if location/GPS feature of device is enabled
					const isLocationEnabled = await this.isLocationEnabled();
					if (!isLocationEnabled) {
						this.toastService.showToast({
							message: this.translateService.instant(
								'toast.permissions.error.deviceGeolocDisabled'
							),
							duration: 5000
						});
						reject();
					}
					// Depending on the platform, we will ask for permissions or not, but initialization must happen anyway at the right moment
					else if (this.platform.is('android')) {
						try {
							const grantedPermissions =
								await this.diagnostic.requestRuntimePermissions([
									this.diagnostic.permission.ACCESS_COARSE_LOCATION,
									this.diagnostic.permission.ACCESS_FINE_LOCATION,
									this.diagnostic.permission.READ_PHONE_STATE
								]);
							// Forward permission status to hide track location card on time
							if (
								grantedPermissions.ACCESS_COARSE_LOCATION ===
									this.diagnostic.permissionStatus.GRANTED &&
								grantedPermissions.ACCESS_FINE_LOCATION ===
									this.diagnostic.permissionStatus.GRANTED &&
								grantedPermissions.READ_PHONE_STATE ===
									this.diagnostic.permissionStatus.GRANTED
							) {
								this.nextPrivacyStatus(true);
							}
							
							// Check granted unique device id needed permissions
							if (
								grantedPermissions.READ_PHONE_STATE ===
								this.diagnostic.permissionStatus.GRANTED
							) {
								await this.initiateGuidService();
							} else {
								this.toastService.showToast({
									message: this.translateService.instant(
										'toast.permissions.error.guidNotGranted'
									),
									duration: 5000
								});
							}

							// Check if both inits were successful
							const initSuccess =
								this.isGeolocationInitialized &&
								this.isUniqueDeviceIdInitialized;

							this.areBothGeolocAndGuidPermitted = initSuccess;

							this.permissionDB
								.set(
									PERMISSION_CONSTANTS.APP_PERMISSIONS.GEOLOC_AND_GUID,
									initSuccess
								)
								.then(
									() => {
										this.appDataStore.resetPrivacyPermission();
										resolve(undefined);
									},
									error => {
										this.loggerService.error(
											this,
											`permissionDB SET FAIL for: ${
												PERMISSION_CONSTANTS.APP_PERMISSIONS.GEOLOC_AND_GUID
											} ${true}. `,
											error
										);
										reject(error);
									}
								);

							return;
						} catch (error) {
							this.loggerService.error(
								this,
								`Something went wrong while requesting android needed permissions for geolocation and guid. `,
								error
							);
						}
					} else if (this.platform.is('ios')) {
						promiseArray.push(this.initiateGuidService());
						this.setWhenUpdatePromisesDone(promiseArray, resolve, reject);
					} else {
						promiseArray.push(this.initiateGuidService());
						this.setWhenUpdatePromisesDone(promiseArray, resolve, reject);
					}
				}
			} else {
				this.permissionDB
					.set(PERMISSION_CONSTANTS.APP_PERMISSIONS.GEOLOC_AND_GUID, false)
					.then(
						() => {
							this.areBothGeolocAndGuidPermitted = false;
							this.nextPrivacyStatus(false);
							resolve(undefined);
						},
						error => {
							this.loggerService.error(
								this,
								`permissionDB SET FAIL for: ${
									PERMISSION_CONSTANTS.APP_PERMISSIONS.GEOLOC_AND_GUID
								} ${false}. `,
								error
							);
							reject(error);
						}
					);
			}
		});
		return promise;
	}

	private nextPrivacyStatus(status: boolean) {
		this.privacyPermissionStatus.next(status);
	}

	/**
	 * When initializations are done, we set the db value up to be done before putting the areBothGeolocAndGuidPermitted flag on
	 */
	private setWhenUpdatePromisesDone(
		promiseArray: Promise<any>[],
		resolveFn,
		rejectFn
	): void {
		Promise.all(promiseArray).then(
			() => {
				if (this.isGeolocationInitialized && this.isUniqueDeviceIdInitialized) {
					this.nextPrivacyStatus(true);
					this.permissionDB
						.set(PERMISSION_CONSTANTS.APP_PERMISSIONS.GEOLOC_AND_GUID, true)
						.then(
							() => {
								this.areBothGeolocAndGuidPermitted = true;
								this.appDataStore.resetPrivacyPermission();
								resolveFn();
							},
							error => {
								this.loggerService.error(
									this,
									`permissionDB SET FAIL for: ${
										PERMISSION_CONSTANTS.APP_PERMISSIONS.GEOLOC_AND_GUID
									} ${true}. `,
									error
								);
								rejectFn(error);
							}
						);
				} else {
					this.areBothGeolocAndGuidPermitted = false;
					rejectFn();
				}
			},
			error => {
				this.loggerService.error(
					this,
					`Something went wrong while initializing geoloc & guid services. `,
					error
				);
				rejectFn(error);
			}
		);
	}

	/**
	 * Initialization of guid service
	 */
	private initiateGuidService(): Promise<any> {
		return new Promise<object[]>((resolve, reject) => {
			this.uniqueDeviceIdService.init().then(
				() => {
					this.isUniqueDeviceIdInitialized = true;
					resolve(undefined);
				},
				error => {
					this.isUniqueDeviceIdInitialized = false;
					this.toastService.showToast({
						message: this.translateService.instant(
							'toast.permissions.error.guidNotInit'
						),
						duration: 5000
					});
					this.loggerService.error(this, error);
					reject(error);
				}
			);
		});
	}

	/**
	 * Show popup when iOS geolocation initialization cannot start as permissions are done.
	 * Popup shows the user the possibility to go the device app settings and turn on permissions for GPS.
	 */
	private showPopupPermissions(
		title: string,
		message: string
	): Promise<boolean> {
		return new Promise<boolean>(async resolve => {
			let didAccept = false;
			const alert = await this.alertService.showAlert({
				header: this.translateService.instant(title),
				message: this.translateService.instant(message),
				buttons: [
					{
						text: this.translateService.instant('common.denyAndContinue')
					},
					{
						text: this.translateService.instant('common.goToAppSettings'),
						handler: () => {
							this.diagnostic.switchToSettings();
							didAccept = true;
						}
					}
				]
			});
			alert.onDidDismiss().then(() => resolve(didAccept));
		});
	}

	public async statusPermissionCamera(): Promise<boolean> {
		if (this.platform.is('android')) {
			return await this.isAndroidCameraAuthorized();
		} else if (this.platform.is('ios')) {
			return this.diagnostic.isCameraAuthorized();
		} else {
			return await this.isWebCameraAuthorized();
		}
	}

	private async isAndroidCameraAuthorized() {
		const permissionsStatus =
			await this.diagnostic.getPermissionsAuthorizationStatus([
				this.diagnostic.permission.CAMERA
			]);
		return (
			permissionsStatus.CAMERA === this.diagnostic.permissionStatus.GRANTED
		);
	}

	private async isWebCameraAuthorized() {
		const name = 'camera' as PermissionName;
		const { state } = await navigator.permissions.query({ name });
		return state == 'granted';
	}

	/**
	 * Request permissions for camera. If iOS platform, also request for photos.
	 */
	public getImagesPermission(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.diagnostic.requestCameraAuthorization().then(
				(permission: string) => {
					if (permission === this.diagnostic.permissionStatus.GRANTED) {
						resolve(undefined);
					} else {
						reject('toast.permissions.error.cameraDenied');
					}
				},
				error => reject(error.message)
			);
		});
	}

	/**
	 * Request permissions for camera. If iOS platform, also request for photos.
	 */
	public async getScanPermission(): Promise<any> {
		if (this.platformService.isMobile()) {
			await this.handleDeniedCameraPermission();
			return this.requestMobileScanPermission();
		} else {
			return this.requestWebScanPermission();
		}
	}

	private requestMobileScanPermission(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			if (this.platform.is('ios')) {
				this.getImagesPermission()
					.then(() => resolve(undefined))
					.catch(error => reject('toast.permissions.error.cameraDenied'));
			} else {
				this.diagnostic
					.requestRuntimePermissions([this.diagnostic.permission.CAMERA])
					.then(
						permission => {
							if (
								permission.CAMERA === this.diagnostic.permissionStatus.GRANTED
							) {
								resolve(undefined);
							} else {
								reject('toast.permissions.error.cameraDenied');
							}
						},
						error => reject(error.message)
					);
			}
		});
	}

	private async requestWebScanPermission(): Promise<void | string> {
		return new Promise((resolve, reject) => {
			try {
				if (
					'mediaDevices' in navigator &&
					'getUserMedia' in navigator.mediaDevices
				) {
					navigator.mediaDevices.getUserMedia({ video: true });
				}

				resolve();
			} catch (error) {
				reject('toast.permissions.error.cameraRequestFailed');
			}
		});
	}

	/**
	 * Check camera permissions
	 * For a status of DENIED_ALWAYS open app settings for the user to grant them manually
	 */
	private handleDeniedCameraPermission(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.getCameraAuthorizationStatus().then(
				authorizationStatus => {
					if (
						authorizationStatus ===
						this.diagnostic.permissionStatus.DENIED_ALWAYS
					) {
						this.showPopupPermissions(
							'dialog.cameraPermissions.title',
							'dialog.cameraPermissions.message'
						).then(wentToAppPermissions => {
							if (wentToAppPermissions) {
								this.platform.resume.pipe(first()).subscribe(() => {
									this.getCameraAuthorizationStatus().then(
										authorizationStatus => {
											if (
												authorizationStatus ===
												this.diagnostic.permissionStatus.GRANTED
											) {
												resolve(undefined);
											} else {
												reject('toast.permissions.error.cameraDenied');
											}
										},
										() => {
											reject('toast.permissions.error.cameraRequestFailed');
										}
									);
								});
							} else {
								reject('toast.permissions.error.cameraDenied');
							}
						});
					} else {
						resolve(undefined);
					}
				},
				() => reject('toast.permissions.error.cameraRequestFailed')
			);
		});
	}

	private async getCameraAuthorizationStatus(): Promise<any> {
		if (this.platform.is('android')) {
			return this.diagnostic.getPermissionAuthorizationStatus(
				this.diagnostic.permission.CAMERA
			);
		} else if (this.platform.is('ios')) {
			return this.diagnostic.getCameraAuthorizationStatus();
		} else {
			return Promise.reject();
		}
	}

	public async handleDeniedGeolocAndGuidPermission() {
		try {
			await this.checkGeolocAndGuidPermission();
		} catch (error) {
			this.toastService.showToast({
				message: this.translateService.instant(error),
				duration: 5000
			});
		}
	}

	/**
	 * Check geoloc and guid permissions for android (for iOS this is handled in initiateGeolocService())
	 * For a status of DENIED_ALWAYS open app settings for the user to grant them manually
	 */
	private checkGeolocAndGuidPermission(): Promise<any> {
		if (this.platform.is('android')) {
			return new Promise<any>((resolve, reject) => {
				this.diagnostic
					.getPermissionsAuthorizationStatus([
						this.diagnostic.permission.ACCESS_COARSE_LOCATION,
						this.diagnostic.permission.ACCESS_FINE_LOCATION,
						this.diagnostic.permission.READ_PHONE_STATE
					])
					.then(
						permissionsStatus => {
							if (
								permissionsStatus.ACCESS_COARSE_LOCATION ===
									this.diagnostic.permissionStatus.DENIED_ALWAYS ||
								permissionsStatus.ACCESS_FINE_LOCATION ===
									this.diagnostic.permissionStatus.DENIED_ALWAYS ||
								permissionsStatus.READ_PHONE_STATE ===
									this.diagnostic.permissionStatus.DENIED_ALWAYS
							) {
								this.showPopupPermissions(
									'dialog.locationAndPhonePermissions.title',
									'dialog.locationAndPhonePermissions.message'
								).then(wentToAppPermissions => {
									if (wentToAppPermissions) {
										this.platform.resume.pipe(first()).subscribe(() => {
											this.diagnostic
												.getPermissionsAuthorizationStatus([
													this.diagnostic.permission.ACCESS_COARSE_LOCATION,
													this.diagnostic.permission.ACCESS_FINE_LOCATION,
													this.diagnostic.permission.READ_PHONE_STATE
												])
												.then(
													permissionsStatus => {
														if (
															permissionsStatus.ACCESS_COARSE_LOCATION ===
																this.diagnostic.permissionStatus.GRANTED &&
															permissionsStatus.ACCESS_FINE_LOCATION ===
																this.diagnostic.permissionStatus.GRANTED &&
															permissionsStatus.READ_PHONE_STATE ===
																this.diagnostic.permissionStatus.GRANTED
														) {
															resolve(undefined);
														} else {
															reject(
																'toast.permissions.error.geolocAndGuidDenied'
															);
														}
													},
													() => {
														reject(
															'toast.permissions.error.geolocAndGuidRequestFailed'
														);
													}
												);
										});
									} else {
										reject('toast.permissions.error.geolocAndGuidDenied');
									}
								});
							} else {
								resolve(undefined);
							}
						},
						() => reject('toast.permissions.error.geolocAndGuidRequestFailed')
					);
			});
		}
	}

	public async isLocationEnabled() {
		const isLocationEnabled = await this.diagnostic.isLocationEnabled();
		return isLocationEnabled;
	}
}
