// Basic
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

// Models
import { Favorite } from '../../core/model/favorite';

// TODO: provide these service and config depending on the platform
import { StorageService } from '@providers/services/storage.service';
import { favoriteConfig } from '@config/config.module';

@Injectable({ providedIn: 'root' })
export class FavoriteDatabaseService {
	private favoriteDatabase: Storage;

	constructor(private storageService: StorageService) {
		this.favoriteDatabase = this.storageService.create(
			favoriteConfig.databaseConfig
		);
	}

	public get(key: string): Promise<Favorite> {
		return this.storageService.get(
			this.favoriteDatabase,
			favoriteConfig.encryption,
			key
		);
	}

	public set(key: string, value: Favorite): Promise<any> {
		return this.storageService.set(
			this.favoriteDatabase,
			favoriteConfig.encryption,
			key,
			value
		);
	}

	public getAll(): Promise<any> {
		return this.storageService.getAll(
			this.favoriteDatabase,
			favoriteConfig.encryption
		);
	}

	public remove(key: string) {
		return this.storageService.remove(this.favoriteDatabase, key);
	}

	public removeAll() {
		return this.storageService.removeAll(this.favoriteDatabase);
	}
}
