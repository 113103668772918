// Basic
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

import * as packageJSON from '../../package.json';

/**
 * Service to publish the configuration of the environment.
 */
@Injectable({ providedIn: 'root' })
export class ConfigurationService {
	/**
	 * variable to bind the environment from the window to the service.
	 */
	public static environment = environment;

	/**
	 * variable to bind the package.json from the window to the service
	 */
	public static packageApp = packageJSON['default'];
}
