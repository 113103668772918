import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ConfigurationService } from '@core/core.module';
import { MachineModule } from '@modules/machine';
import { DocumentModule } from '@modules/document';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FavoritesModule } from '@modules/favorites';
import { RecentlyModule } from '@modules/recently';
import { AppStatusModule } from '@modules/app';

@NgModule({
	imports: [
		AppStatusModule,
		CommonModule,
		MachineModule,
		DocumentModule,
		FavoritesModule,
		RecentlyModule,
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: ConfigurationService.environment.$environment.isProduction
		})
	],
	declarations: []
})
export class RootStoreModule {}
