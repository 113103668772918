import { Injectable } from '@angular/core';
import { UtilsService } from '@providers/services/utils.service';
import { MACHINE_CONSTANTS } from '../constants/machine.constant';
import { Machine } from '../model/machine';

@Injectable({ providedIn: 'root' })
export class ExtractMachineInfoService {
	public extractMachineInfo(version: any, action: any) {
		const machineInfo = version.currentversion || version;
		const internalElement =
			machineInfo.machine.CAEXFile.InstanceHierarchy.InternalElement;
		const productDesignation = this.utilsService
			.findAttributeValue(
				MACHINE_CONSTANTS.PRODUCT_DESIGNATION,
				internalElement
			)
			.toUpperCase();
		const madeIn = this.utilsService
			.findAttributeValueByRefSemantic(
				MACHINE_CONSTANTS.MADE_IN.REF,
				internalElement
			)
			.toUpperCase();

		const machine: Machine = {
			serialnumber: action.serialnumber,
			manufacturerproductnumber: action.manufacturerproductnumber,
			productdesignation: productDesignation,
			madeIn,
			currentversion: {
				plantversion: machineInfo.plantversion,
				versioncreated: machineInfo.versioncreated,
				machine: machineInfo.machine
			},
			versionhistory: [],
			isFavorite: false
		};
		return machine;
	}

	constructor(private utilsService: UtilsService) {}
}
