import { createAction, props } from '@ngrx/store';
import { Recently, SynchroModel } from '../core/model/recently';

export enum RecentlyActionTypes {
	requestRecents = '[DB-RECENTLY] REQUEST RECENTS',
	saveRecents = '[RECENTLY] SAVE RECENTS',
	saveRecentlyIntoDB = '[DB-RECENTLY] SAVE RECENTLY',
	saveRecently = '[RECENTLY] SAVE RECENTLY',
	updateRecentlyStatus = '[RECENTLY] UPDATE RECENTLY',
	updateRecentlyIsBookmarked = '[RECENTLY] UPDATE IS BOOKMARKED',
	deleteRecentlyFromDB = '[DB-RECENTLY] DELETE RECENTLY',
	removeRecently = '[RECENTLY] REMOVE RECENTLY'
}

export const requestRecents = createAction(
	RecentlyActionTypes.requestRecents,
	props<Record<string, unknown>>()
);

export const saveRecents = createAction(
	RecentlyActionTypes.saveRecents,
	props<{ recently: Recently[] }>()
);

export const saveRecentlyIntoDB = createAction(
	RecentlyActionTypes.saveRecentlyIntoDB,
	props<{ recently: Recently }>()
);

export const saveRecently = createAction(
	RecentlyActionTypes.saveRecently,
	props<{ recently: Recently }>()
);

export const updateRecentlyStatusIntoDB = createAction(
	RecentlyActionTypes.updateRecentlyStatus,
	props<{ serialNumber: string; synchroStatus: SynchroModel.SynchroStatus }>()
);

export const updateRecentlyStatus = createAction(
	RecentlyActionTypes.updateRecentlyStatus,
	props<{ serialNumber: string; synchroStatus: SynchroModel.SynchroStatus }>()
);

export const updateRecentlyIsBookmarked = createAction(
	RecentlyActionTypes.updateRecentlyIsBookmarked,
	props<{ serialNumber: string; isBookmarked: boolean }>()
);

export const deleteRecentlyFromDB = createAction(
	RecentlyActionTypes.deleteRecentlyFromDB,
	props<{ recently: Recently }>()
);

export const removeRecently = createAction(
	RecentlyActionTypes.removeRecently,
	props<{ recently: Recently }>()
);
