// Basic
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

// Services
import { StorageService } from '@providers/services/storage.service';

// Config
import { versionHistoryConfig } from '@config/config.module';

/**
 * Version history database service to access to the persistance data.
 */
@Injectable({ providedIn: 'root' })
export class VersionHistoryDatabaseService {
	/**
	 * document database instance
	 */
	private versionHistoryDatabase: Storage;

	/**
	 * Construtor where we import all needed in the service.
	 * @param storageService instance of the storage general service.
	 */
	constructor(private storageService: StorageService) {
		this.versionHistoryDatabase = this.storageService.create(
			versionHistoryConfig.databaseConfig
		);
	}

	/**
	 * Method to get a version history stored inside database.
	 * @param key Primary key to get the value inside the database.
	 */
	public get(key: string): Promise<any> {
		return this.storageService.get(
			this.versionHistoryDatabase,
			versionHistoryConfig.encryption,
			key
		);
	}

	/**
	 * Method to set a version history inside database.
	 * @param key Primary key to be stored inside the database.
	 * @param value Value to be stored inside the database
	 */
	public set(key: string, value: any): Promise<any> {
		return this.storageService.set(
			this.versionHistoryDatabase,
			versionHistoryConfig.encryption,
			key,
			value
		);
	}

	/**
	 * Method to get all the version histories from the database.
	 */
	public getAll(): Promise<any> {
		return this.storageService.getAll(
			this.versionHistoryDatabase,
			versionHistoryConfig.encryption
		);
	}

	/**
	 * Method to get all the version histories from the database.
	 */
	public getAllWithKeys(): Promise<any> {
		return this.storageService.getAllWithKeys(
			this.versionHistoryDatabase,
			versionHistoryConfig.encryption
		);
	}

	/**
	 * Delete the version history of the key provided by parameter.
	 * @param key Primary key to delete the value.
	 */
	public remove(key: string) {
		return this.storageService.remove(this.versionHistoryDatabase, key);
	}

	/**
	 * Delete all entries on the version history database.
	 */
	public removeAll() {
		return this.storageService.removeAll(this.versionHistoryDatabase);
	}
}
