// Basic
import { Injectable } from '@angular/core';
import { ApiGenericProvider } from '@core/core.module';

// Models
import { ApiTranslationModel } from '@core/model/api/api-translation.model';

/**
 * Service that translate dynamic values that might change during development.
 */
@Injectable({ providedIn: 'root' })
export class ApiTranslationProvider extends ApiGenericProvider {
	/**
	 * ApiTranslationProvider constructor
	 * @param http Angular's http service to make calls against a server.
	 * @param geolocationService Geolocation service to get the current location.
	 * @param uniqueDeviceIDService Service to get the devices' unique ID.
	 * @param loggerService Service to log.
	 */
	constructor() {
		super(`translation/`);
	}

	/**
	 * Get key's translation and returns the one in the current language
	 * @param key Key string to get translation
	 * @return Promise with response. If no key is provided, promise is rejected with message
	 */
	public getTranslation(
		key: string,
		machineProductNumber: string
	): Promise<ApiTranslationModel> {
		const promise = new Promise<ApiTranslationModel>((resolve, reject) => {
			if (key && machineProductNumber) {
				const uri = `${encodeURIComponent(key)}`;
				this.get(uri).then(
					(response: ApiTranslationModel) => resolve(response),
					() => reject({ message: `Key has no length, key: ${key}` })
				);
			} else {
				reject({ message: `Key has no length, key: ${key}` });
			}
		});
		return promise;
	}
}
