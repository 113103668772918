import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap } from 'rxjs/operators';
import {
	FavoritesActionTypes,
	saveFavorites,
	removeFavorite,
	saveFavorite
} from './favorites.actions';
import { FavoritesService } from '../infrastructure/service/favorites.service';
import { Favorite } from '../core/model/favorite';
import { forkJoin, from } from 'rxjs';
import { updateRecentlyIsBookmarked } from '@modules/recently/actions';

@Injectable()
export class FavoritesEffects {
	getFavorites$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(FavoritesActionTypes.requestFavorites),
			mergeMap(() => {
				return from(this.favoriteService.getAllFavorites()).pipe(
					map(favorites => {
						return saveFavorites({ favorites });
					})
				);
			})
		);
	});

	saveFavoriteIntoDB$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(FavoritesActionTypes.saveFavoriteIntoDB),
			mergeMap((action: any) => this.saveFavoriteAction(action.favorite)),
			mergeMap(saveFavoriteAction => saveFavoriteAction)
		);
	});

	addFavoriteLocation$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(FavoritesActionTypes.addFavoriteLocation),
			mergeMap((action: any) =>
				from(this.favoriteService.getFavorite(action.serialnumber)).pipe(
					filter(favorite => favorite),
					mergeMap(favorite => this.saveFavoriteAction(favorite)),
					mergeMap(saveFavoriteAction => saveFavoriteAction)
				)
			)
		);
	});

	private async saveFavoriteAction(favorite: Favorite) {
		return [
			saveFavorite({ favorite }),
			updateRecentlyIsBookmarked({
				serialNumber: favorite.serialNumber,
				isBookmarked: true
			})
		];
	}

	deleteFavoriteFromDB$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(FavoritesActionTypes.deleteFavoriteFromDB),
			mergeMap((action: any) =>
				from(
					this.favoriteService.removeFavorite(action.favorite.serialNumber)
				).pipe(
					mergeMap(() => [
						removeFavorite({ favorite: action.favorite }),
						updateRecentlyIsBookmarked({
							serialNumber: action.favorite.serialNumber,
							isBookmarked: false
						})
					])
				)
			)
		);
	});

	constructor(
		private actions$: Actions,
		private favoriteService: FavoritesService
	) {}
}
