import { createFeatureSelector, createSelector } from '@ngrx/store';
import { favoritesFeatureKey, FavoritesState } from './favorites.reducer';

export const getFavoritesState =
	createFeatureSelector<FavoritesState>(favoritesFeatureKey);
export const deleteFavoriteState =
	createFeatureSelector<FavoritesState>(favoritesFeatureKey);

export const getFavorites = createSelector(
	getFavoritesState,
	(state: FavoritesState) => state.favorites
);

export const deleteFavorite = createSelector(
	deleteFavoriteState,
	(state: FavoritesState) => state.favorites
);
