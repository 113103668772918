/**
 * Database name and version.
 */
export const DB_NAME = {
	NAME: 'siemens_database',
	VERSION: '1',
	SUFFIX: 'db'
};

/**
 * Secret to encript the data into database.
 */
export const SECRET = 'B_p=H8%8e:`VsZ';
