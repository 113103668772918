import { createReducer, Action, on } from '@ngrx/store';

import * as MachineActions from './machine.actions';
import { Machine } from '../core/model/machine';

export const machineFeatureKey = 'machine';

export interface MachineState {
	machine: Machine;
}

export const initialState: MachineState = {
	machine: {
		serialnumber: '',
		manufacturerproductnumber: '',
		productdesignation: '',
		madeIn: '',
		currentversion: {
			plantversion: '',
			versioncreated: '',
			machine: {}
		},
		versionhistory: [],
		isFavorite: false,
		location: null
	}
};

const machineReducer = createReducer(
	initialState,
	on(MachineActions.saveMachineVersion, (state, { machine }) => {
		return {
			machine: {
				...machine,
				versionhistory: state.machine.versionhistory
			}
		};
	}),
	on(MachineActions.saveMachineLocation, (state, { location }) => {
		if (location && !state.machine.location) {
			return {
				machine: {
					...state.machine,
					location: location
				}
			};
		} else {
			return {
				machine: { ...state.machine }
			};
		}
	}),
	on(MachineActions.saveMachineVersionHistory, (state, { versionhistory }) => {
		return {
			machine: {
				...state.machine,
				versionhistory
			}
		};
	}),
	on(
		MachineActions.updateCachedMachineVersion,
		(state, { version, cached }) => {
			const newVersionHistory = state.machine.versionhistory.map(
				historyVersion => {
					if (historyVersion.version === version) {
						return {
							...historyVersion,
							cached: true
						};
					} else {
						return historyVersion;
					}
				}
			);

			return {
				machine: {
					...state.machine,
					versionhistory: newVersionHistory
				}
			};
		}
	)
);

export function reducer(state: MachineState, action: Action) {
	return machineReducer(state, action);
}
