import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   // console.log(`%c Sending HTTP request to: ${req.method} ${req.url}`, "color: green");
  //  console.log(`Cuerpo de solicitud: ${JSON.stringify(req.body)}`);
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
     //     console.log(`%c Receiving HTTP response: ${req.method} ${req.url}`, "color: green")
     //     console.log(event.body, "color: green");
        }
      }),
      catchError((error: HttpErrorResponse) => {
    //    console.error(`Receiving HTTP response with the error: ${req.method} ${req.url}`);
    //    console.error(error);
        return throwError(error);
      })
    );
  }
}
