// Basic
import { Injectable } from '@angular/core';
import { Storage, StorageConfig } from '@ionic/storage-angular';

// Services
import { LoggerService } from '@providers/services/logger.service';
import { WorkerService } from '@providers/services/worker.service';

// Constants
import { SECRET } from '@core/constants/storage.constants';

// Encripter library
import { AES, enc } from 'crypto-js';

/**
 * Service to handler the storage with the application.
 */
@Injectable({ providedIn: 'root' })
export class StorageService {
	/**
	 * Construtor where we import all needed in the service.
	 * @param loggerService logger service
	 * @param workerService worker service
	 */
	constructor(
		private loggerService: LoggerService,
		private workerService: WorkerService,
		private Storage: Storage
	) {}

	/**
	 * Method to create a new database with a custom configuration.
	 * @param config Database configuration.
	 */
	public create(config: StorageConfig): Storage {
		const storage = new Storage({
			name:config.name,
			storeName:config.storeName
		});
		storage.create();
		return storage;
	}

	/**
	 * Method to get an object stored inside database and decript.
	 * @param storage storage instance
	 * @param encryption if the database has encryption.
	 * @param key Primary key to get the value inside the database.
	 */
	public get(storage: Storage, encryption: boolean, key: string): Promise<any> {
		return this._get(storage, encryption, key);
	}

	/**
	 * Method to set an object stored inside database. If needs encription, it leaves that task to web worker.
	 * @param storage storage instance
	 * @param encryption if the database has encryption.
	 * @param key Primary key to be stored inside the database.
	 * @param value Value to be stored inside the database
	 */
	public set(
		storage: Storage,
		encryption: boolean,
		key: string,
		value: any
	): Promise<any> {
		if (encryption) {
			const promise = new Promise<any>((resolve, reject) => {
				this.workerService
					.performTask('encryptAndStringify', value)
					.then(data => {
						// TODO: identify the method
						storage.set(key, data).then(
							answer => resolve(answer),
							error => reject(error)
						);
					});
			});
			return promise;
		} else {
			return storage.set(key, value);
		}
	}

	/**
	 * Method to get everything stored inside database and decript.
	 * @param storage storage instance
	 * @param encryption if the database has encryption.
	 */
	public getAll(storage: Storage, encryption: boolean): Promise<any> {
		return storage
			.keys()
			.then(keys =>
				Promise.all(keys.map(k => this._get(storage, encryption, k)))
			);
	}

	/**
	 * Method to get everything stored inside database and decript.
	 * @param storage storage instance
	 * @param encryption if the database has encryption.
	 */
	public getAllWithKeys(storage: Storage, encryption: boolean): Promise<any> {
		return storage.keys().then(keys =>
			Promise.all(
				keys.map(async k => {
					return {
						key: k,
						values: await this._get(storage, encryption, k)
					};
				})
			)
		);
	}

	/**
	 * Delete the value of the key provided by parameter.
	 * @param storage storage instance
	 * @param key Primary key to delete the value
	 */
	public remove(storage: Storage, key: string) {
		return storage.remove(key);
	}

	/**
	 * remoove all the storage.
	 * @param storage storage instance.
	 */
	public removeAll(storage: Storage): Promise<any> {
		return storage.clear();
	}

	/**
	 * Private method to get an object stored inside database and decript.
	 * @param storage storage instance
	 * @param encryption if the database has encryption.
	 * @param key Primary key to get the value inside the database.
	 */
	private _get(
		storage: Storage,
		encryption: boolean,
		key: string
	): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			storage.get(key).then(
				value => {
					if (value) {
						if (encryption) {
							try {
								resolve(
									JSON.parse(AES.decrypt(value, SECRET).toString(enc.Utf8))
								);
							} catch (exception) {
								resolve(AES.decrypt(value, SECRET).toString(enc.Utf8));
							}
						} else {
							try {
								resolve(JSON.parse(value));
							} catch (exception) {
								resolve(value);
							}
						}
					} else {
						reject();
					}
				},
				error => this.loggerService.error(this, error)
			);
		});
	}
}
