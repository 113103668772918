// Basic
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

// Services
import { StorageService } from '@providers/services/storage.service';

// Model
import { OfflineMachine } from '@modules/machine/core/model/machine';

// Config
import { offlineSerialNumberConfig } from '@config/config.module';

/**
 * Offline serial number database service to access to the persistance data.
 */
@Injectable({ providedIn: 'root' })
export class OfflineSerialNumberDatabaseService {
	/**
	 * Offline serial number database instance
	 */
	private offlineSerialNumberDatabase: Storage;

	/**
	 * Construtor where we import all needed in the service.
	 * @param storageService instance of the storage general service.
	 */
	constructor(private storageService: StorageService) {
		this.offlineSerialNumberDatabase = this.storageService.create(
			offlineSerialNumberConfig.databaseConfig
		);
	}

	/**
	 * Method to get a serial number stored inside database.
	 * @param key Primary key to get the value inside the database.
	 */
	public get(key: string): Promise<any> {
		return this.storageService.get(
			this.offlineSerialNumberDatabase,
			offlineSerialNumberConfig.encryption,
			key
		);
	}

	/**
	 * Method to set a serial number inside database.
	 * @param key Primary key to be stored inside the database.
	 * @param value Value to be stored inside the database
	 */
	public set(key: string, value: OfflineMachine): Promise<any> {
		return this.storageService.set(
			this.offlineSerialNumberDatabase,
			offlineSerialNumberConfig.encryption,
			key,
			value
		);
	}

	/**
	 * Method to get all the serial numbers from the database.
	 */
	public getAll(): Promise<any> {
		return this.storageService.getAll(
			this.offlineSerialNumberDatabase,
			offlineSerialNumberConfig.encryption
		);
	}

	/**
	 * Delete the serial number of the key provided by parameter.
	 * @param key Primary key to delete the value.
	 */
	public remove(key: string) {
		return this.storageService.remove(this.offlineSerialNumberDatabase, key);
	}

	/**
	 * Delete all entries on the serial numbers database.
	 */
	public removeAll() {
		return this.storageService.removeAll(this.offlineSerialNumberDatabase);
	}
}
