// Basic
import { Injectable } from '@angular/core';

// Api Services
import { ApiTranslationProvider } from '@providers/api/api-translation.service';
import { ApiTranslationDatabaseService } from '@providers/db/api-translation-database.service';

// Services
import { TranslateService } from '@providers/services/translate.service';
import { LoggerService } from '@providers/services/logger.service';
import { UtilsService } from '@providers/services/utils.service';

// Models
import { ApiTranslationModel } from '@core/model/api/api-translation.model';
import { AttributeNodeModel } from '@core/model/attribute-node.model';

// Constants
import { MACHINE_CONSTANTS } from '@modules/machine/core/constants/machine.constant';

/**
 * Store to handle the translations.
 */
@Injectable({ providedIn: 'root' })
export class TranslationStore {
	/**
	 * current translations.
	 */
	private currentTranslations: ApiTranslationModel[];

	/**
	 * Wether the app is currently connected or not.
	 */
	private isOnline: boolean;

	/**
	 * Constructor to declare all the necesary to initialize the class.
	 * @param ApiTranslationProvider Service that communicates with translation services
	 * @param translationDB translations database access.
	 * @param translateService Service that provides the translations.
	 * @param loggerService logger service
	 * @param utilsService utils service
	 */
	constructor(
		private apiTranslationProvider: ApiTranslationProvider,
		private translationDB: ApiTranslationDatabaseService,
		private translateService: TranslateService,
		private loggerService: LoggerService,
		private utilsService: UtilsService
	) {
		this.currentTranslations = [];
		this.translationDB.getAll().then(
			translations =>
				translations.forEach(
					(translation: ApiTranslationModel) =>
						(this.currentTranslations[translation.key] = translation)
				),
			() => this.loggerService.error(this, 'Load FAIL from translations DB.')
		);
	}

	/**
	 * Performs a different action depending on being online/offline and being the first time current translation is needed.
	 * If online, fetches and updates the translation. If offline, fetches it from translation DB.
	 * @param key key to translate.
	 * @returns A promise that contains the string with the translation in the language that is currently set.
	 * If tranlsation does not exists, promise is rejected with message.
	 */
	public retrieveTranslationByKey(
		key: string,
		manufacturerProductNumber: string
	): Promise<string> {
		const promise = new Promise<string>((resolve, reject) => {
			if (this.isOnline) {
				// Online so we update the translation in the db.
				this.getTranslationByKey(key, manufacturerProductNumber).then(
					response => {
						if (
							response.translation &&
							response.translation[this.translateService.currentLanguage()]
						) {
							// resolve with proper language.
							resolve(
								response.translation[this.translateService.currentLanguage()]
							);
						} else {
							reject({
								message: `There is no matching translation for the given key. Key = ${key}, translation = ${response.translation}`
							});
						}
					},
					error => reject(error)
				);
			} else {
				this.translationDB.get(key).then(
					response => {
						if (
							response.translation &&
							response.translation[this.translateService.currentLanguage()]
						) {
							// resolve with proper language.
							resolve(
								response.translation[this.translateService.currentLanguage()]
							);
						} else {
							reject({
								message: `There is no matching translation for the given key. Key = ${key}, translation = ${response.translation}`
							});
						}
					},
					() => {
						reject({
							message: `There is no matching translation for the key ${key}`
						});
					}
				);
			}
		});
		return promise;
	}

	/**
	 * Fetches information of a translation by key and stores in the DB. If already done previously, then just return this._currentTranslations.
	 * @param key key to translate.
	 */
	public getTranslationByKey(
		key: any,
		manufacturerProductNumber: string
	): Promise<any> {
		const promise = new Promise<any>((resolve, reject) => {
			if (this.currentTranslations[key]) {
				resolve(this.currentTranslations[key]);
			} else {
				this.apiTranslationProvider
					.getTranslation(key, manufacturerProductNumber)
					.then(
						response => {
							this.translationDB.set(key, response).then(
								() => {
									this.currentTranslations[key] = response;
									resolve(response);
								},
								() =>
									reject({
										message: `There was an error storing the translation ${key} in the translation DB.`
									})
							);
						},
						() =>
							reject({
								message: `There was an error retrieving the translation ${key} from service.`
							})
					);
			}
		});
		return promise;
	}

	/**
	 * Cache translations for a given machine version.
	 * @param machine The machine to cache the translations for.
	 */
	public cacheTranslationsFromMachine(machine) {
		const manufacturerProductNumber = machine.manufacturerproductnumber;
		const machineInfo = machine.currentversion || machine;
		const internalElement =
			machineInfo.machine.CAEXFile.InstanceHierarchy.InternalElement;

		const productDesignation = this.utilsService
			.findAttributeValue(
				MACHINE_CONSTANTS.PRODUCT_DESIGNATION,
				internalElement
			)
			.toUpperCase();
		const designationKey =
			this.getProductDesignationKeyForSuffixTranslations(productDesignation);
        
		const suffixAttributes =
			this.findManufacturerProductOrderSuffix(internalElement);
		if (suffixAttributes) {
			suffixAttributes
				.map(suffix => suffix.Value)
				.forEach(suffixValue => {
					if (suffixValue) {
						this.getTranslationByKey(
							designationKey.concat('-', suffixValue),
							manufacturerProductNumber
						);
					}
				});
		}
	}

	/**
	 * Function to return the product designation in lower case letters without whitespaces.
	 * In order to translate manufacturer product order suffixes.
	 * @param productDesignation product designation, stored in machine model
	 */
	public getProductDesignationKeyForSuffixTranslations(
		productDesignation: string
	): string {
		return productDesignation.replace(/\s/g, '').toLowerCase();
	}

	/**
	 * Method used to find the manufacturer product order suffixes on the machine object
	 * @param node The current node where to look for
	 */
	private findManufacturerProductOrderSuffix(node: AttributeNodeModel) {
		const attributeNode = this.utilsService.findAttributeNode(
			MACHINE_CONSTANTS.PRODUCT_ORDER_SUFFIX,
			node
		);
		if (attributeNode) {
			const value = attributeNode.Attribute;
			if (value !== undefined) {
				return value;
			} else {
				return '';
			}
		}
		return '';
	}
}
