import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import * as favoritesReducer from './state/favorites.reducer';
import { StoreModule } from '@ngrx/store';
import { FavoritesEffects } from './state/favorites.effects';
import { EffectsModule } from '@ngrx/effects';
import { ConfigurationService } from '@core/configuration.service';

@NgModule({
	imports: [
		HttpClientModule,
		CommonModule,
		StoreModule.forFeature(
			favoritesReducer.favoritesFeatureKey,
			favoritesReducer.reducer
		),
		EffectsModule.forFeature([FavoritesEffects])
	],
	providers: [FavoritesEffects, ConfigurationService]
})
export class FavoritesModule {}
