import { createAction, props } from '@ngrx/store';
import { RecacheRunningModeModel } from '../core/model/app.model';

export enum AppActionTypes {
	saveIsOnline = '[APP] SAVE IS ONLINE',
	presentLoading = '[APP] PRESENT LOADING',
	dismissLoading = '[APP] DISMISS LOADING',
	saveRecacheRunningMode = '[APP] SAVE RECACHE RUNNING MODE'
}

export const saveIsOnline = createAction(
	AppActionTypes.saveIsOnline,
	props<{
		isOnline: boolean;
	}>()
);

export const presentLoading = createAction(
	AppActionTypes.presentLoading,
	props<Record<string, unknown>>()
);

export const dismissLoading = createAction(
	AppActionTypes.dismissLoading,
	props<Record<string, unknown>>()
);

export const saveRecacheRunningMode = createAction(
	AppActionTypes.saveRecacheRunningMode,
	props<{
		recacheRunningMode: RecacheRunningModeModel.RecacheRunningModeType;
	}>()
);
