/**
 * Constants for the permissions of each platform.
 */
export const PERMISSION_CONSTANTS = {
	IOS: {
		STATUS_DENIED: 'denied',
		STATUS_NOT_DETERMINED: 'not_determined',
		STATUS_AUTHORIZED: 'authorized',
		STATUS_AUTHORIZED_ON_USE: 'authorized_when_in_use'
	},
	ANDROID: {},
	APP_PERMISSIONS: {
		GEOLOC_AND_GUID: 'PERM_GEOLOC&GUID'
	}
};
