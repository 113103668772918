export interface Recently {
	serialNumber: string;
	productNumber?: string;
	hasMachineLocation: boolean;
	isBookmarked: boolean;
	synchroStatus: SynchroModel.SynchroStatus;
	lastSearched: Date;
}
export namespace SynchroModel {
	export type SynchroStatus =
		| 'OK'
		| 'PENDING'
		| 'NOT_FOUND'
		| 'FETCHING'
		| 'NOT_CACHED';

	export const OK: SynchroStatus = 'OK';
	export const PENDING: SynchroStatus = 'PENDING';
	export const NOT_FOUND: SynchroStatus = 'NOT_FOUND';
	export const FETCHING: SynchroStatus = 'FETCHING';
	export const NOT_CACHED: SynchroStatus = 'NOT_CACHED';
}
