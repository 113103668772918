// Basic
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

// Services
import { StorageService } from '@providers/services/storage.service';

// Config
import { settingsConfig } from '@config/config.module';

/**
 * Settings database service to access to the persistance data.
 */
@Injectable({ providedIn: 'root' })
export class SettingsDatabaseService {
	/**
	 * Settings database instance
	 */
	private settingsDatabase: Storage;

	/**
	 * Construtor where we import all needed in the service.
	 * @param storageService instance of the storage general service.
	 */
	constructor(private storageService: StorageService) {
		this.settingsDatabase = this.storageService.create(
			settingsConfig.databaseConfig
		);
	}

	/**
	 * Method to get a setting stored inside database.
	 * @param key Primary key to get the value inside the database.
	 */
	public get(key: string): Promise<any> {
		return this.storageService.get(
			this.settingsDatabase,
			settingsConfig.encryption,
			key
		);
	}

	/**
	 * Method to set a setting inside database.
	 * @param key Primary key to be stored inside the database.
	 * @param value Value to be stored inside the database
	 */
	public set(key: string, value: any): Promise<any> {
		return this.storageService.set(
			this.settingsDatabase,
			settingsConfig.encryption,
			key,
			value
		);
	}

	/**
	 * Method to get all the settings from the database.
	 */
	public getAll(): Promise<any> {
		return this.storageService.getAll(
			this.settingsDatabase,
			settingsConfig.encryption
		);
	}

	/**
	 * Delete the setting of the key provided by parameter.
	 * @param key Primary key to delete the value.
	 */
	public remove(key: string) {
		return this.storageService.remove(this.settingsDatabase, key);
	}

	/**
	 * Delete all entries on the settings database.
	 */
	public removeAll() {
		return this.storageService.removeAll(this.settingsDatabase);
	}
}
