// Basic
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

// Services
import { BackButtonService } from './back-button.service';
import { AlertOptions } from '@ionic/core';

/**
 * Service to create generic alerts.
 */
@Injectable({ providedIn: 'root' })
export class AlertService {
	/**
	 * Alert service constructor.
	 * @param alertController Instance of alert controller.
	 * @param backButtonService Service to customize the hardware back button action
	 */
	constructor(
		private alertController: AlertController,
		private backButtonService: BackButtonService
	) {}

	/**
	 * Shows an alert with options.
	 * @param options Alert options from @link https://ionicframework.com/docs/api/components/alert/AlertController/#advanced
	 * @returns Alert item to be able to manipulate it.
	 */
	public async showAlert(options: AlertOptions) {
		const alert = await this.alertController.create(options);
		alert.present();
		const unregisterBackButton = this.backButtonService.registerNewBack(
			() => alert.dismiss(),
			100
		);
		alert.onDidDismiss().then(() => {
			this.backButtonService.unregisterBack(unregisterBackButton);
		});
		return alert;
	}
}
