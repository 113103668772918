// Basic
import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Services
import { ConfigurationService } from './configuration.service';
import { HttpTokenInterceptor } from './interceptor/http.interceptor.token';
import { AppInjector } from './app.injector';

/**
 * Module to export the core services of the application.
 */
@NgModule({
	imports: [CommonModule],
	exports: [],
	declarations: [],
	providers: [
		ConfigurationService,
		{ provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true }
	]
})
export class CoreModule {
	constructor(injector: Injector) {
		AppInjector.injector = injector;
	}
}

// Reexport all models
export * from './configuration.service';
export * from './api.service';
