export interface App {
	isOnline: boolean;
	isLoading: boolean;
	recacheRunningMode: RecacheRunningModeModel.RecacheRunningModeType;
}

export namespace RecacheRunningModeModel {
	export type RecacheRunningModeType =
		| 'IDLE'
		| 'INITIALIZATION'
		| 'LOGIN'
		| 'LOGOUT';

	export const IDLE: RecacheRunningModeType = 'IDLE';
	export const INITIALIZATION: RecacheRunningModeType = 'INITIALIZATION';
	export const LOGIN: RecacheRunningModeType = 'LOGIN';
	export const LOGOUT: RecacheRunningModeType = 'LOGOUT';
}
