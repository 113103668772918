/**
 *  Screen Media breakpoints to MediaService.
 */
export interface MediaConfig {
  [breakpoint: string]: number;
}

/**
 * Breakpoints values used on the MediaService.
 */
export const mediaConfig: MediaConfig = {
  XS: 0,
  MD: 992,
  LG: 1280
};
