import { Injectable } from '@angular/core';
import { ToastOptions } from '@ionic/core';
import { ToastController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class ToastService {
	constructor(private readonly toastController: ToastController) {}

	public async showToast(options: ToastOptions) {
		const toast = await this.toastController.create(options);
		toast.present();
	}
}
