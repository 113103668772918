// Basic
import { Injectable } from '@angular/core';

// Api Services
import { SettingsDatabaseService } from '@providers/db/settings-database.service';

// Constants
import { SETTINGS_CONSTANTS } from '@core/constants/settings.constants';

/**
 * Store to handle the application settings.
 */
@Injectable({ providedIn: 'root' })
export class SettingsStore {
	// local value to store beep settings for synchronous requests
	private beepValue: boolean;

	/**
	 * Constructor to declare all the necesary to initialize the class.
	 * @param settingsDB settings database access.
	 */
	constructor(private settingsDB: SettingsDatabaseService) {}

	/**
	 * Gets the language key from the settings DB
	 */
	public getLanguage(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.settingsDB.get(SETTINGS_CONSTANTS.LANGUAGES.STORAGE_KEY).then(
				languagekey => resolve(languagekey),
				error => reject(error)
			);
		});
	}

	/**
	 * Sets the language key on settings DB
	 * @param value the value of the language
	 */
	public setLanguage(value: any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.settingsDB.set(SETTINGS_CONSTANTS.LANGUAGES.STORAGE_KEY, value).then(
				() => resolve(undefined),
				error => reject(error)
			);
		});
	}

	/**
	 * Gets the beeping key from the settings DB
	 */
	public getBeepPreference(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.settingsDB.get(SETTINGS_CONSTANTS.BEEP_PREFERENCE).then(
				beepOnScan => resolve(beepOnScan),
				error => reject(error)
			);
		});
	}

	/**
	 * Sets the beeping key on settings DB
	 * @param value the value of the preference
	 */
	public setBeepPreference(value: boolean): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.settingsDB.set(SETTINGS_CONSTANTS.BEEP_PREFERENCE, value).then(
				() => {
					this.beepValue = value;
					resolve(undefined);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Gets the user's beep preference
	 * Retrieving this value with a promise would cause the value to be present
	 * after the settings-beep-component is already rendered
	 */
	public getBeepSettings(): boolean {
		return this.beepValue;
	}
}
