import { createReducer, Action, on } from '@ngrx/store';
import * as DocumentActions from './document.actions';

export const documentFeatureKey = 'document';

export interface DocumentState {
	documents: any;
}

export const initialState: DocumentState = {
	documents: []
};

const machineReducer = createReducer(
	initialState,
	on(DocumentActions.requestDocumentSucess, (state, { result }) => {
		const newState = { ...state };

		newState.documents = result;
		return newState;
	})
);

export function reducer(state: DocumentState, action: Action) {
	return machineReducer(state, action);
}
