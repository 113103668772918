// Basic
import { Injectable } from '@angular/core';

// Moment
import * as moment from 'moment';

/**
 * Service to handler the use of workers for parallel tasks.
 */
@Injectable({ providedIn: 'root' })
export class WorkerService {
	/**
	 * WebWorker to process encryption on set methods for databases.
	 */
	private mainWorker: Worker;

	/**
	 * Array of promises that keep consistency for ther mainWorker works.
	 */
	private workerPromises: any[];

	/**
	 * Construtor where we import all needed in the service.
	 */
	constructor() {
		this.mainWorker = new Worker('./assets/workers/main-worker.js');
		this.workerPromises = [];
		// Setup listener for when worker has finished the task
		this.mainWorker.onmessage = event => {
			const promiseIndex = this.workerPromises.findIndex(workerPromise => {
				return workerPromise.id === event.data.id;
			});
			if (promiseIndex !== undefined && this.workerPromises[promiseIndex]) {
				this.workerPromises[promiseIndex].resolve(event.data.result);
				this.workerPromises.splice(promiseIndex, 1);
			}
		};
	}

	/**
	 * Registers a new task and returns a promise to trigger when finished.
	 * @param command string of the command to perform.
	 * @param data data sent necessary to the task.
	 */
	public performTask(command: string, data: any): Promise<any> {
		const id = moment().valueOf();
		// Create a promise and store it in workerPromises, will be resolved when necessary.
		const returnedPromise: Promise<any> = new Promise<any>(
			(workerResolve, workerReject) => {
				this.workerPromises.push({
					id,
					resolve: workerResolve,
					reject: workerReject
				});
			}
		);
		// Call the worker with the data
		this.mainWorker.postMessage({ id, command, data });
		return returnedPromise;
	}
}
