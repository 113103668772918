import { IonicConfig } from '@ionic/core';

/**
 * Ionic configuration properties
 * All available properties:
 * https://ionicframework.com/docs/api/config/Config/
 *
 * Default values:
 * https://ionicframework.com/docs/theming/platform-specific-styles/
 */
export const ionicConfig: IonicConfig = {

  // UI
  backButtonText: '',
  swipeBackEnabled: false,
  innerHTMLTemplatesEnabled: true
};
