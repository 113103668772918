import { Injectable } from '@angular/core';

import { ApplicationDataStore } from '@providers/stores/application-data-store.service';
import { NavController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class OnBoardingGuard  {
	constructor(
		private navCtrl: NavController,
		private appDataStore: ApplicationDataStore
	) {}

	canActivate() {
		return this.appDataStore
			.isfirstTimeInitApplication()
			.then(() => {
				return true;
			})
			.catch(() => {
				this.navCtrl.navigateRoot('/onboarding');
				return false;
			});
	}
}
