/**
 * Settings constants.
 */
export const SETTINGS_CONSTANTS = {
	LANGUAGES: {
		STORAGE_KEY: 'CURRENT_LANGUAGE',
		AVAILABLE_LANGUAGES: ['en', 'de', 'cn'] // First language is the default one. // Provide translations in assets + register also language for LanguageNameByI18nCodeService
	},
	BEEP_PREFERENCE: 'beep_on_scan',
	SECURE_STORAGE_NAME: 'SiemensSecureStorage'
};
