/**
 * General application data constants.
 */
export const APPLICATION_CONSTANTS = {
	MOBILE_EXCLUDED_NODES: ['ESB Data'],
	STATUSBAR_COLOR: '#009999ff',
	PRIVACY_POPUP_STATUS: 'PRIVACY_POPUP_STATUS',
	FIRST_TIME_INIT_APPLICATION: 'FIRST_TIME_INIT_APPLICATION',
	BROWSER_X_GUID: 'X-GUID',
	NAME_FIELD_ATRIBUTE: '_attributes',
	SEPARATION_CHARACTER_SERIALNUMBER_PRODUCTNUMBER: '+',
	MAX_IMAGE_COUNT: 5,
	COPYRIGTH: {
		COMPANY: 'Siemens',
		MINIMUM_LIMIT: 2019,
		SIMBOL: '©'
	}
};
