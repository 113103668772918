import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs/internal/Observable';
import { UserModel } from '@core/model/user.model';
import { ConfigurationService } from '@core/configuration.service';
const { redirectUri } = ConfigurationService.environment.mobileAuthConfig;

@Injectable({
	providedIn: 'root'
})
export class AuthNativeService {
	constructor(private readonly auth: AuthService) { }

	public get currentUser$(): Observable<UserModel> {
		return new Observable(subscribe => {
			this.auth.idTokenClaims$.subscribe(
				({ name, email, picture: avatar, exp: expiresIn, __raw: token }) => {
					subscribe.next({ name, email, avatar, expiresIn, token });
					subscribe.complete();
				},
				error => subscribe.error(error)
			);
		});
	}

	login() {
		return this.auth
			.buildAuthorizeUrl({ display: 'sup' } as any)
			.pipe(mergeMap(url => Browser.open({ url, windowName: '_self' })));
	}

	logout() {
		this.auth
			.buildLogoutUrl({ display: 'sup', returnTo:redirectUri } as any)
			.pipe(
				mergeMap(async url => {
					
					await Browser.open({ url, windowName: '_self' });
					this.auth.logout({ localOnly: true });
					window.location.reload();
				})
			)
			.subscribe();
	}
}
