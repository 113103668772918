import { from, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { ApiService } from '@core/services/api/api.service';
import { MachineDatabaseService } from '../db/machine-database.service';
import { MACHINE_CONSTANTS } from '../../core/constants/machine.constant';
import { APPLICATION_CONSTANTS } from '@core/constants/application-data.constants';
import {
	Machine,
	MachineHistoryVersion,
	OfflineMachine
} from '@modules/machine/core/model/machine';
import { VersionHistoryDatabaseService } from '../db/version-history-database.service';
import {
	ApiErrorBadOutput,
	ApiErrorExceededRequests,
	ApiErrorForbidden,
	ApiErrorNotFound,
	ApiErrorTimeOut,
	ApiErrorUnavailableData,
	ApiErrorUnauthorized
} from '@core/error/api-error/api-error.module';
import { TranslateService } from '@providers/services/translate.service';
import { ToastService } from '@providers/services/toast.service';
import { DbErrorNotFound } from '@core/error/db/db-error.module';
import { OfflineSerialNumberDatabaseService } from '../db/offline-serial-number-database.service';

@Injectable({ providedIn: 'root' })
export class MachineService extends ApiService {

	constructor(
		private readonly router: Router,
		public readonly http: HttpClient,
		private readonly toastService: ToastService,
		private readonly machineDB: MachineDatabaseService,
		private readonly translateService: TranslateService,
		private readonly offlineDB: OfflineSerialNumberDatabaseService,
		private readonly versionHistoryDB: VersionHistoryDatabaseService,
	) {
		super(http);
	}

	public getLatestVersion(
		machineSerialNumber: string,
		machineProductNumber: string
	) {
		return this._getMachineVersion(
			machineSerialNumber,
			machineProductNumber,
			'latest'
		);
	}

	public getFirstVersion(
		machineSerialNumber: string,
		machineProductNumber: string
	) {
		return this._getMachineVersion(
			machineSerialNumber,
			machineProductNumber,
			'first'
		);
	}

	public getMachineVersion(
		machineSerialNumber: string,
		machineProductNumber: string,
		version: string
	) {
		return this._getMachineVersion(
			machineSerialNumber,
			machineProductNumber,
			version
		);
	}

	private _getMachineVersion(
		machineSerialNumber: string,
		machineProductNumber: string,
		version: string
	) {
		const encodeVersion = encodeURIComponent(version);
		const uri = `version/${encodeVersion}`;

		return this.performRequestMachineResource(
			machineSerialNumber,
			machineProductNumber,
			uri
		);
	}

	public getMachineVersionHistory(
		machineSerialNumber: string,
		machineProductNumber: string
	) {
		return this.performRequestMachineResource(
			machineSerialNumber,
			machineProductNumber,
			'versionhistory'
		);
	}

	private performRequestMachineResource(
		machineSerialNumber: string,
		machineProductNumber: string,
		uri: string
	) {
		const key = `${machineSerialNumber}${APPLICATION_CONSTANTS.SEPARATION_CHARACTER_SERIALNUMBER_PRODUCTNUMBER}${machineProductNumber}`;
		const enhancedUri = `${MACHINE_CONSTANTS.MACHINE_URI}/${encodeURIComponent(
			key
		)}/${uri}`;
		return this.get(enhancedUri);
	}

	public getLatestVersionFromDB(
		machineSerialNumber: string,
		machineProductNumber: string
	) {
		return from(
			this.machineDB.getLatest(machineSerialNumber, machineProductNumber)
		);
	}

	public getMachineVersionFromDB(machineSerialNumber: string, version: string) {
		return from(this.machineDB.get(machineSerialNumber, version));
	}

	public saveMachineIntoDB(machine: Machine, isLatest?: boolean): Promise<any> {
		let setResult: Promise<any>;
		if (isLatest) {
			setResult = this.machineDB.setLatest(machine.serialnumber, machine);
		}
		setResult = this.machineDB.set(
			machine.serialnumber,
			machine.currentversion.plantversion,
			machine
		);
		return setResult;
	}

	public async updateCachedMachineVersionIntoDB(
		machine: Machine,
		cached: boolean
	) {
		const machineVersionHistory = await this.versionHistoryDB.get(
			machine.serialnumber
		);

		if (machineVersionHistory) {
			const existingVersionIndex = machineVersionHistory.findIndex(
				(machineVersion: MachineHistoryVersion) =>
					machineVersion.version === machine.currentversion.plantversion
			);

			if (existingVersionIndex !== -1) {
				machineVersionHistory[existingVersionIndex] = {
					...machineVersionHistory[existingVersionIndex],
					cached
				};

				await this.versionHistoryDB.set(
					machine.serialnumber,
					machineVersionHistory
				);
			}
		} else {
			await this.versionHistoryDB.set(
				machine.serialnumber,
				machineVersionHistory
			);
		}
	}

	public getAllMachinesFromDB() {
		return from(this.machineDB.getAllWithKeys());
	}

	public getMachineVersionHistoryFromDB(machineSerialNumber: string) {
		return from(this.versionHistoryDB.get(machineSerialNumber));
	}

	public saveMachineVersionHistoryIntoDB(
		serialNumber: string,
		versionHistory: any[]
	) {
		return from(this.versionHistoryDB.set(serialNumber, versionHistory));
	}

	public getAllMachinesVersionHistoryFromDB() {
		return from(this.versionHistoryDB.getAllWithKeys());
	}

	public openMachine(
		machineSerialNumber: string,
		machineProductNumber: string
	) {
		const key = `${machineSerialNumber}${APPLICATION_CONSTANTS.SEPARATION_CHARACTER_SERIALNUMBER_PRODUCTNUMBER}${machineProductNumber}`;
		this.router.navigate(['/main/machine'], {
			queryParams: {
				machineSerialNumberAndProductNumber: key
			},
			replaceUrl: true
		});
	}

	public getAllOfflineMachines(): Promise<any> {
		return this.offlineDB.getAll();
	}

	public removeOfflineMachine(serialNumber: string): Promise<any> {
		return this.offlineDB.remove(serialNumber);
	}

	public setOfflineMachine(serialNumber: string, machine: OfflineMachine) {
		return this.offlineDB.set(serialNumber, machine);
	}

	public removeAll(): Promise<any> {
		return this.offlineDB.removeAll();
	}

	public showRequestMachineError(
		serialNumber: string,
		machineProductNumber: string,
		error
	) {
		let toastErrorMessage = '';
		if (error) {
			switch (error.constructor) {
				case ApiErrorNotFound:
					toastErrorMessage = this.translateService.instant(
						'toast.searchMachine.error4.message',
						{ serialNumber, productNumber: machineProductNumber }
					);
					break;
				case ApiErrorTimeOut:
					toastErrorMessage = this.translateService.instant(
						'toast.searchMachine.error5.message'
					);
					break;
				case ApiErrorUnavailableData:
					toastErrorMessage = this.translateService.instant(
						'toast.searchMachine.error9.message'
					);
					break;
				case ApiErrorBadOutput:
				case ApiErrorUnauthorized:
					toastErrorMessage = this.translateService.instant(
						'toast.searchMachine.error16.message'
					);
					window.location.reload();
					break;
				case ApiErrorForbidden:
					this.router.navigate(["main/search-machine"]);
					toastErrorMessage = this.translateService.instant(
						'toast.searchMachine.error13.message',
						{ serialNumber, productNumber: machineProductNumber }
					);
					break;
				case ApiErrorExceededRequests:
					const hoursLeft = Math.ceil(error.resetTime / (1000 * 3600));
					toastErrorMessage = this.translateService.instant(
						'toast.searchMachine.error10.message',
						{ hoursLeft }
					);
					break;
				case DbErrorNotFound:
					toastErrorMessage = this.translateService.instant(
						'toast.searchMachine.machineNotFoundInCache.message',
						{ serialNumber, productNumber: machineProductNumber }
					);
					break;
				default:
					toastErrorMessage = this.translateService.instant(
						'toast.searchMachine.error6.message'
					);
			}
		} else {
			toastErrorMessage = this.translateService.instant(
				'toast.searchMachine.error6.message'
			);
		}

		this.toastService.showToast({
			message: toastErrorMessage,
			duration: 5000
		});
	}

	private availableMotorTypes$: Observable<Object> = null;

	public getMachineTypes() {
		if (!this.availableMotorTypes$) {
			this.availableMotorTypes$ = this.get(`machine-type`).pipe(shareReplay(1));
		}

		return this.availableMotorTypes$;
	}
}
