import { createFeatureSelector, createSelector } from '@ngrx/store';
import { recentlyFeatureKey, RecentlyState } from './recently.reducer';

export const getRecentlyState =
	createFeatureSelector<RecentlyState>(recentlyFeatureKey);

export const getRecents = createSelector(
	getRecentlyState,
	(state: RecentlyState) => state.recently
);
