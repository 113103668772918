import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { TranslateService as Translate } from '@ngx-translate/core';

import { UserModel } from '@core/model/user.model';
import { JwtService } from '@core/services/jwt.service';
import { UserStore } from '@providers/stores/user-store.service';
import { LoggerService } from '@providers/services/logger.service';
import { AuthWebService } from '@core/services/auth/auth-web.service';
import { PlatformService } from '@providers/services/platform.service';
import { AuthNativeService } from '@core/services/auth/auth-native.service';
import { ToastService } from '@providers/services/toast.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	isBrowser: boolean;
	isAndroid:boolean;
	constructor(
		private readonly translate: Translate,
		private readonly userStore: UserStore,
		private readonly jwtService: JwtService,
		private readonly authWeb: AuthWebService,
		private readonly authService: Auth0Service,
		private readonly toastService: ToastService,
		private readonly loggerService: LoggerService,
		private readonly authNative: AuthNativeService,
		private readonly platformService: PlatformService
	) {
		this.isBrowser = this.platformService.isBrowser();
		this.isAndroid = this.platformService.isAndroid();
	}

	public get isAuthenticated$() {
		return this.authService.isAuthenticated$;
	}

	public get currentUser$(): Observable<UserModel> {
		return new Observable(subscribe => {
			this.authService.idTokenClaims$.subscribe(
				response => {
					if (response) {
						const {
							name,
							email,
							picture: avatar,
							exp: expiresIn,
							__raw: token
						} = response;
						subscribe.next({ name, email, avatar, expiresIn, token });
					}

					subscribe.complete();
				},
				error => subscribe.error(error)
			);
		});
	}

	public async login() {
		try {
			if (this.isBrowser) {
				this.authWeb.login().subscribe({
					complete: this.handleLoadUser.bind(this),
					error: this.loggerService.error.bind(this)
				});
			} else {
				this.authNative.login().subscribe({
					complete: this.handleLoadUser.bind(this),
					error: this.loggerService.error.bind(this)
				});
			}
		} catch (error) {
			this.loggerService.error(this, ' Error auth store login');
		}
	}

	public async logout() {
		try {
			if (this.isBrowser) {
				this.authWeb.logout();
			} else {
				this.authNative.logout();
			}

			this.userStore.removeUserApplication();
		} catch (error) {
			this.loggerService.error(this, ' Error auth store logout');
		}
	}

	public removeUserInApplication() {
		this.userStore.removeUserApplication();
	}

	public async handleLoadUser() {
		return new Promise((resolve, reject) => {
			this.currentUser$.subscribe(
				async user => {
					await this.userStore.setCurrentUser(user);
					resolve(user);
				},
				error => {
					this.loggerService.error(this, ' Error auth store loadUser');
					reject(error);
				}
			);
		});
	}

	public handleRedirectCallback(url?: string) {
		try {

			if(this.isAndroid){
				return this.authService
				.handleRedirectCallback(url)
				.subscribe({
					complete: this.handleLoadUser.bind(this),
					error: this.handleRedirectError.bind(this)
				});
			} else {
				return this.authService
				.handleRedirectCallback(url)
				.pipe(mergeMap(async () => Browser.close()))
				.subscribe({
					complete: this.handleLoadUser.bind(this),
					error: this.handleRedirectError.bind(this)
				});

			}	

			
		} catch (error) {
			if(error.code !== "UNIMPLEMENTED"){
				this.handleRedirectError(error);
			}
		}
	}

	private async handleRedirectError(error: any) {
		this.loggerService.error(this, ' Error auth handling redirection', error);
		this.toastService.showToast({
			message: this.translate.instant('toast.searchMachine.error16.message'),
			duration: 5000,
		});
	
		await this.handleLoadUser();
		window.location.reload();
	}

	public async refreshToken() {
		try {
			const user: UserModel = this.userStore.getCurrentUser();
			user.token = await this.authService.getAccessTokenSilently().toPromise();
			await this.userStore.setCurrentUser(user);
			return user.token;
		} catch (error) {
			this.loggerService.error(this, 'Error refreshing token');
		}
	}

	public async getTokenUser() {
		try {
			const user: UserModel = this.userStore.getCurrentUser();

			if (!user.token) {
				return this.jwtService.getBaseToken();
			}

			const expiresDate = new Date();
			const decodedToken: any = this.jwtService.decodeToken(user.token);
			const expiresIn = decodedToken.exp * 1000;

			expiresDate.setTime(expiresIn);

			if (expiresDate < new Date()) {
				return await this.refreshToken();
			}

			return user.token;
		} catch (error) {
			this.loggerService.error(this, ' Error logout');
		}
	}

	public async getUserRole() {
		const userToken = await this.getTokenUser();
		return this.jwtService.getRoles(userToken);
	}
}
