import { Injectable } from '@angular/core';

import { APPLICATION_CONSTANTS } from '@core/constants/application-data.constants';
import { ApiService } from '@core/services/api/api.service';
import { DOCUMENT_CONSTANTS } from '../constants/documentconstant';
import { AttributeNodeModel } from '@core/model/attribute-node.model';

/**
 * Service that returns machine information from server.
 */
@Injectable({ providedIn: 'root' })
export class ExtractDocumentIdsService extends ApiService {
	public extractDocumentIdsFromMachineVersion(version: any): string[] {
		let documentIDArray: string[] = [];
		const machineInfo = version.currentversion || version;
		const machineDocuments: any[] =
			machineInfo.machine.CAEXFile.InstanceHierarchy.InternalElement
				.ExternalInterface;
		if (Array.isArray(machineDocuments)) {
			machineDocuments.forEach(document => {
				// Filter out anything that is not a manual or certificate
				if (
					document[DOCUMENT_CONSTANTS.NAME_FIELD_ATRIBUTE].RefBaseClassPath ===
					DOCUMENT_CONSTANTS.IS_MANUAL_OR_CERTIFICATE
				) {
					// It's a manual or certificate.
					if (Array.isArray(document.Attribute)) {
						for (const attribute of document.Attribute) {
							const documentID: string = this.findAttributeValue(
								DOCUMENT_CONSTANTS.DOCUMENT_ID,
								attribute
							);
							if (documentID !== '') {
								documentIDArray.push(documentID);
							}
						}
					} else if (
						document.Attribute !== null &&
						typeof document.Attribute === 'object'
					) {
						const documentID: string = this.findAttributeValue(
							DOCUMENT_CONSTANTS.DOCUMENT_ID,
							document.Attribute
						);
						if (documentID !== '') {
							documentIDArray.push(documentID);
						}
					}
				}
			});
			// TODO: is this filter necessary? if so, justify
			documentIDArray = documentIDArray.filter(
				(value, index, self) => self.indexOf(value) === index
			);
		}
		return documentIDArray;
	}

	public findAttributeValue(attributeName: string, node: AttributeNodeModel) {
		const attributeNode = this.findAttributeNode(attributeName, node);
		if (attributeNode) {
			const value = attributeNode.Value;
			if (value !== undefined) {
				return value;
			} else {
				return '';
			}
		}
		return '';
	}

	public findAttributeNode(
		attributeName: string,
		node: AttributeNodeModel
	): AttributeNodeModel {
		try {
			if (
				attributeName.localeCompare(
					node[APPLICATION_CONSTANTS.NAME_FIELD_ATRIBUTE].Name
				) === 0
			) {
				return node;
			}
			if (node.Attribute !== undefined) {
				for (const childAttribute of node.Attribute) {
					const result = this.findAttributeNode(attributeName, childAttribute);
					if (result !== undefined) {
						return result;
					}
				}
			}
		} catch (error) {
			return undefined;
		}
	}
}
