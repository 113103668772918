import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map } from 'rxjs/operators';
import { DocumentActionTypes, requestDocumentSucess } from './document.actions';
import { DocumentApiService } from '../infrastructure/service/document.service';
import { ExtractDocumentIdsService } from '../core/service/extract-documentids.service';
import { of, forkJoin } from 'rxjs';

@Injectable()
export class DocumentEffects {
	constructor(
		private actions$: Actions,
		private documentApiService: DocumentApiService,
		private extractDocumenIdsService: ExtractDocumentIdsService
	) {}

	getDocumentByMachineVersion$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DocumentActionTypes.requestDocument),
			mergeMap((action: any) => {
				const documentIdsFromMachineVersion =
					this.extractDocumenIdsService.extractDocumentIdsFromMachineVersion(
						action.version
					);

				if (documentIdsFromMachineVersion.length > 0) {
					const requestDocumentIds = documentIdsFromMachineVersion.map(
						documentId =>
							this.documentApiService.getDocumentInfoById(documentId)
					);

					return forkJoin(requestDocumentIds).pipe(
						map(result => {
							const filterResult = result.filter(
								obj => Object.keys(obj).length > 0
							); // Filter for successful requests
							return requestDocumentSucess({ result: filterResult });
						})
					);
				} else {
					return of(requestDocumentSucess({ result: [] }));
				}
			})
		);
	});

	getDocumentByMachineVersionFromDB$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DocumentActionTypes.requestDocumentFromDB),
			mergeMap((action: any) => {
				const documentIdsFromMachineVersion =
					this.extractDocumenIdsService.extractDocumentIdsFromMachineVersion(
						action.version
					);

				if (documentIdsFromMachineVersion.length > 0) {
					const requestDocumentIds = documentIdsFromMachineVersion.map(
						documentId => this.documentApiService.getDocumentFromDB(documentId)
					);

					return forkJoin(requestDocumentIds).pipe(
						map(result => {
							const filterResult = result.filter(
								obj => Object.keys(obj).length > 0
							); // Filter for successful requests
							return requestDocumentSucess({ result: filterResult });
						})
					);
				} else {
					return of(requestDocumentSucess({ result: [] }));
				}
			})
		);
	});
}
