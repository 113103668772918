import { createFeatureSelector, createSelector } from '@ngrx/store';
import { appFeatureKey, AppStatusState } from './app.reducer';

export const getAppState = createFeatureSelector<AppStatusState>(appFeatureKey);

export const getIsOnline = createSelector(
	getAppState,
	(state: AppStatusState) => state.app.isOnline
);

export const getIsLoading = createSelector(
	getAppState,
	(state: AppStatusState) => state.app.isLoading
);

export const getRecacheRunningMode = createSelector(
	getAppState,
	(state: AppStatusState) => state.app.recacheRunningMode
);
