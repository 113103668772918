// Basic
import { Injectable } from '@angular/core';

// Models
import { AttributeNodeModel } from '@core/model/attribute-node.model';
import { APPLICATION_CONSTANTS } from '@core/constants/application-data.constants';

/**
 * Utility class to support some seach methods in machine JSON
 */
@Injectable({ providedIn: 'root' })
export class UtilsService {
	/**
	 * Find an attribute node by name. Recursive function
	 * @param attributeName Attribute name
	 * @param node The current node where to look for
	 */
	public findAttributeNode(
		attributeName: string,
		node: AttributeNodeModel
	): AttributeNodeModel {
		try {
			if (
				attributeName.localeCompare(
					node[APPLICATION_CONSTANTS.NAME_FIELD_ATRIBUTE].Name
				) === 0
			) {
				return node;
			}
			if (node.Attribute !== undefined) {
				for (const childAttribute of node.Attribute) {
					const result = this.findAttributeNode(attributeName, childAttribute);
					if (result !== undefined) {
						return result;
					}
				}
			}
		} catch (error) {
			return undefined;
		}
	}

	/**
	 * Find an attribute node by refSemantic. Recursive function
	 * @param refSemantic refSemantic of the node
	 * @param node The current node where to look for
	 */
	public findAttributeNodeByRef(
		refSemantic: string,
		node: AttributeNodeModel
	): AttributeNodeModel {
		try {
			if (
				node.RefSemantic &&
				node.RefSemantic[APPLICATION_CONSTANTS.NAME_FIELD_ATRIBUTE]
					.CorrespondingAttributePath === refSemantic
			) {
				return node;
			}
			if (node.Attribute !== undefined) {
				for (const childAttribute of node.Attribute) {
					const result = this.findAttributeNodeByRef(
						refSemantic,
						childAttribute
					);
					if (result !== undefined) {
						return result;
					}
				}
			}
		} catch (error) {
			return undefined;
		}
	}

	/**
	 * Method used to find the attribute value on the machine object
	 * @param attributeName Attribute name
	 * @param node The current node where to look for
	 */
	public findAttributeValue(attributeName: string, node: AttributeNodeModel) {
		const attributeNode = this.findAttributeNode(attributeName, node);
		if (attributeNode) {
			const value = attributeNode.Value;
			if (value !== undefined) {
				return value;
			} else {
				return '';
			}
		}
		return '';
	}

	/**
	 * Method used to find the attribute value on the machine object
	 * @param refSemantic refSemantic of the node
	 * @param node The current node where to look for
	 */
	public findAttributeValueByRefSemantic(
		refSemantic: string,
		node: AttributeNodeModel
	) {
		const attributeNode = this.findAttributeNodeByRef(refSemantic, node);
		if (attributeNode) {
			if (attributeNode && attributeNode.Value) {
				return attributeNode.Value;
			} else {
				return '';
			}
		}
		return '';
	}
}
