import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

// Models
import { Recently } from '../../core/model/recently';

// TODO: provide these service and config depending on the platform
import { StorageService } from '@providers/services/storage.service';
import { recentlyConfig } from './recently.db.config';

@Injectable({ providedIn: 'root' })
export class RecentlyDatabaseService {
	private recentlyDatabase: Storage;

	constructor(private storageService: StorageService) {
		this.recentlyDatabase = this.storageService.create(
			recentlyConfig.databaseConfig
		);
	}

	public get(key: string): Promise<Recently> {
		return this.storageService.get(
			this.recentlyDatabase,
			recentlyConfig.encryption,
			key
		);
	}

	public set(key: string, value: Recently): Promise<any> {
		return this.storageService.set(
			this.recentlyDatabase,
			recentlyConfig.encryption,
			key,
			value
		);
	}

	public getAll(): Promise<any> {
		return this.storageService.getAll(
			this.recentlyDatabase,
			recentlyConfig.encryption
		);
	}

	public remove(key: string) {
		return this.storageService.remove(this.recentlyDatabase, key);
	}

	public removeAll() {
		return this.storageService.removeAll(this.recentlyDatabase);
	}
}
