import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';

// Services
import { ApiService } from '@core/services/api/api.service';
import { ApiMachineProvider } from '@providers/api/api-machine.service';
import { InAppBrowserService } from '@providers/services/in-app-browser.service';
import { ExtractDocumentIdsService } from '@modules/document/core/service/extract-documentids.service';

// Db
import { DocumentDatabaseService } from '@modules/document/infrastructure/db/document-database.service';

// Constants
import { DOCUMENT_CONSTANTS } from '@modules/document/core/constants/documentconstant';

// Models
import { DocumentInfoModel } from '../../core/model/document-info';
import { SignedUrl, SignedCookie } from '@core/model/api/api-document.model';

/**
 * Service that returns machine information from server.
 */
@Injectable({ providedIn: 'root' })
export class DocumentApiService extends ApiService {
	endpoint = 'document/';
	public async getDocumentInfoById(documentId: string): Promise<any> {
		if (!documentId) {
			throw new Error('DocumentId or|and serialNumber is not defined');
		} else {
			const url = `${encodeURIComponent(documentId)}`;
			try {
				return (await this.get(
					this.endpoint + url
				).toPromise()) as Promise<DocumentInfoModel>;
			} catch (error) {
				return {};
			}
		}
	}

	public getDocumentFromDB(documentId: string) {
		return this.documentDB.get(documentId).catch(() => {
			return {};
		});
	}

	public getAllDocumentsFromDB() {
		return from(this.documentDB.getAllWithKeys());
	}

	public removeAll(): Promise<any> {
		return this.documentDB.removeAll();
	}

	private saveDocumentIntoDB(document: DocumentInfoModel): Promise<any> {
		let setResult: Promise<any>;
		setResult = this.documentDB.set(document.documentid, document);
		return setResult;
	}

	public saveDocuments(documents: DocumentInfoModel[]) {
		documents.forEach(document => {
			this.saveDocumentIntoDB(document);
		});
	}

	public cacheDocumentsFromMachine(machine) {
		const documentIdsFromMachineVersion =
			this.extractDocumenIdsService.extractDocumentIdsFromMachineVersion(
				machine
			);

		if (documentIdsFromMachineVersion.length > 0) {
			documentIdsFromMachineVersion.forEach(documentId => {
				this.getDocumentInfoById(documentId).then(document => {
					if (Object.keys(document).length > 0) {
						this.saveDocuments([document]);
					}
				});
			});
		}
	}

	public async openDocument(
		location: string,
		serialNumber: string,
		productNumber: string,
		documenID: string
	) {
		const extensionRegExp = /\.[0-9a-z]+$/i;
		const result = location.match(extensionRegExp);
		if (result) {
			const extension = result[0];
			if (
				DOCUMENT_CONSTANTS.EXTENSIONS_USING_COOKIES.every(cookieExtension =>
					extension.includes(cookieExtension)
				)
			) {
				const response: SignedCookie =
					await this.apiMachineProvider.getCookiesDocument(
						serialNumber,
						productNumber,
						documenID
					);

				this.openDocumentCookies(
					serialNumber,
					productNumber,
					documenID,
					response.cookie
				);
			} else {
				const response: SignedUrl =
					await this.apiMachineProvider.getSignedUrlDocument(
						serialNumber,
						productNumber,
						documenID
					);
				this.inAppBrowserService.create(response.url);
			}
		}
	}

	private openDocumentCookies(
		serialNumber: string,
		productNumber: string,
		documenId: string,
		signedCookies: string
	) {
		const url = this.apiMachineProvider.getUrlOpenDocument(
			serialNumber,
			productNumber,
			documenId,
			signedCookies
		);
		this.inAppBrowserService.create(url);
	}

	constructor(
		public http: HttpClient,
		private documentDB: DocumentDatabaseService,
		private inAppBrowserService: InAppBrowserService,
		private apiMachineProvider: ApiMachineProvider,
		private extractDocumenIdsService: ExtractDocumentIdsService
	) {
		super(http);
	}
}
