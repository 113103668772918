import { createReducer, Action, on } from '@ngrx/store';
import * as AppActions from './app.actions';
import { App, RecacheRunningModeModel } from '../core/model/app.model';

export const appFeatureKey = 'app';

export interface AppStatusState {
	app: App;
}

export const initialState: AppStatusState = {
	app: {
		isOnline: true,
		isLoading: false,
		recacheRunningMode: RecacheRunningModeModel.IDLE
	}
};

const appReducer = createReducer(
	initialState,
	on(AppActions.saveIsOnline, (state, { isOnline }) => {
		return {
			app: {
				...state.app,
				isOnline
			}
		};
	}),
	on(AppActions.presentLoading, (state, {}) => {
		return {
			app: {
				...state.app,
				isLoading: true
			}
		};
	}),
	on(AppActions.dismissLoading, (state, {}) => {
		return {
			app: {
				...state.app,
				isLoading: false
			}
		};
	}),
	on(AppActions.saveRecacheRunningMode, (state, { recacheRunningMode }) => {
		return {
			app: {
				...state.app,
				recacheRunningMode
			}
		};
	})
);

export function reducer(state: AppStatusState, action: Action) {
	return appReducer(state, action);
}
