// Basic
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		IonicModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule
	],
	exports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule],
	providers: []
})
export class SharedModule {}

export * from './media-service/media.service';
export * from './offline-reminder/offline-reminder.service';
