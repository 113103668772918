import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { OnBoardingGuard } from '@core/guard/onboarding.guard';


const routes: Routes = [
	{
		path: '',
		redirectTo: 'main',
		pathMatch: 'full'
	},
	{
		path: 'onboarding',
		loadChildren: () =>
			import('./pages/onboarding/onboarding.module').then(
				m => m.OnboardingPageModule
			)
	},
	{
		canActivate: [OnBoardingGuard],
		path: 'main',
		loadChildren: () =>
			import('./pages/main/main.module').then(m => m.MainPageModule)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
