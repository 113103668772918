import { createFeatureSelector, createSelector } from '@ngrx/store';
import { machineFeatureKey, MachineState } from './machine.reducer';

export const getMachineState =
	createFeatureSelector<MachineState>(machineFeatureKey);

export const getMachine = createSelector(
	getMachineState,
	(state: MachineState) => state.machine
);
