import { share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { UserModel } from '@core/model/user.model';
import { LoggerService } from '@providers/services/logger.service';
import { UserDatabaseService } from '@providers/db/user.service.db';
import { USER_CONSTANTS } from '@core/constants/user.constants';

const DEFAULT_USER = {
	name: '',
	email: '',
	avatar: 'assets/imgs/profile-placeholder.png',
	token: ''
} as UserModel;

@Injectable({ providedIn: 'root' })
export class UserStore {
	public currentUser$ = new BehaviorSubject<UserModel>(DEFAULT_USER);

	constructor(
		private readonly userDB: UserDatabaseService,
		private readonly loggerService: LoggerService
	) {}

	public removeUserApplication() {
		this.userDB
			.remove(USER_CONSTANTS.CURRENT_USER)
			.then(() => this.currentUser$.next(DEFAULT_USER))
			.catch(this.loggerService.error);
	}

	public getCurrentUser(): UserModel {
		return this.currentUser$.value;
	}

	public setCurrentUser(user: UserModel): Promise<void> {
		return new Promise<any>((resolve, reject) => {
			this.userDB.set(USER_CONSTANTS.CURRENT_USER, user).then(
				response => {
					this.currentUser$.next(user);
					resolve(response);
				},
				error => reject(error)
			);
		});
	}
}
