// Basic
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

// Services
import { StorageService } from '@providers/services/storage.service';

// Config
import { machineConfig } from '@config/config.module';
import { AuthService } from '@core/services/auth/auth.service';

// Error
import { DbErrorNotFound } from '@core/error/db/db-error.module';

/**
 * machine database service to access to the persistance data.
 */
@Injectable({ providedIn: 'root' })
export class MachineDatabaseService {
	/**
	 * Machine database instance
	 */
	private machineDatabase: Storage;

	/**
	 * Construtor where we import all needed in the service.
	 * @param storageService instance of the storage general service.
	 */
	constructor(
		private storageService: StorageService,
		private authService: AuthService
	) {
		this.machineDatabase = this.storageService.create(
			machineConfig.databaseConfig
		);
	}

	/**
	 * Method to get the latest version of a machine stored inside database.
	 * @param serialNumber serial number parameter in the database.
	 */
	public async getLatest(
		serialNumber: string,
		machineProductNumber: string
	): Promise<any> {
		const machineVersion = await this._get(serialNumber + '_latest');
		if (machineProductNumber === machineVersion.manufacturerproductnumber) {
			return machineVersion;
		} else {
			throw new DbErrorNotFound();
		}
	}

	/**
	 * Method to get a specific version of a machine stored inside database.
	 * @param serialNumber serial number parameter in the database.
	 * @param version version parameter in the database.
	 */
	public get(serialNumber: string, version: string): Promise<any> {
		return this._get(serialNumber + '_' + version);
	}

	/**
	 * Method to get a version stored inside database.
	 * @param key Primary key to get the value inside the database.
	 */
	private _get(key: string): Promise<any> {
		return this.storageService.get(
			this.machineDatabase,
			machineConfig.encryption,
			key
		);
	}

	/**
	 * Method to set the latest version of a machine inside database.
	 * @param serialNumber serial number parameter in the database.
	 * @param value Value to be stored inside the database
	 */
	public setLatest(serialNumber: string, value: any): Promise<any> {
		return this._set(serialNumber + '_latest', value);
	}

	/**
	 * Method to set a specific version of a machine inside database.
	 * @param serialNumber serial number parameter in the database.
	 * @param version version parameter in the database.
	 * @param value Value to be stored inside the database
	 */
	public set(serialNumber: string, version: string, value: any): Promise<any> {
		return this._set(serialNumber + '_' + version, value);
	}

	/**
	 * Method to set a version inside database.
	 * @param key Primary key to be stored inside the database.
	 * @param value Value to be stored inside the database
	 */
	private async _set(key: string, value: any): Promise<any> {
		const roleValue = { ...value, role: await this.authService.getUserRole() };
		return this.storageService.set(
			this.machineDatabase,
			machineConfig.encryption,
			key,
			roleValue
		);
	}

	/**
	 * Method to get all the machines from the database.
	 */
	public getAll(): Promise<any> {
		return this.storageService.getAll(
			this.machineDatabase,
			machineConfig.encryption
		);
	}

	/**
	 * Method to get all the machines from the database.
	 */
	public getAllWithKeys(): Promise<any> {
		return this.storageService.getAllWithKeys(
			this.machineDatabase,
			machineConfig.encryption
		);
	}

	/**
	 * Delete the machine of the key provided by parameter.
	 * @param key Primary key to delete the value.
	 */
	public _remove(key: string) {
		return this.storageService.remove(this.machineDatabase, key);
	}

	/**
	 * Delete the machine of the key provided by parameter.
	 * @param key Primary key to delete the value.
	 */
	public remove(serialNumber: string, version: string) {
		return this._remove(serialNumber + '_' + version);
	}

	/**
	 * Delete all entries on the machine database.
	 */
	public removeAll() {
		return this.storageService.removeAll(this.machineDatabase);
	}
}
