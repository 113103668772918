// Basic
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

// Services
import { StorageService } from '@providers/services/storage.service';

// Config
import { documentInfoConfig } from '@config/config.module';
import { AuthService } from '@core/services/auth/auth.service';

/**
 * document database service to access to the persistance data.
 */
@Injectable({ providedIn: 'root' })
export class DocumentDatabaseService {
	/**
	 * document database instance
	 */
	private documentDatabase: Storage;

	/**
	 * Construtor where we import all needed in the service.
	 * @param storageService instance of the storage general service.
	 */
	constructor(
		private storageService: StorageService,
		private authService: AuthService
	) {
		this.documentDatabase = this.storageService.create(
			documentInfoConfig.databaseConfig
		);
	}

	/**
	 * Method to get a document stored inside database.
	 * @param key Primary key to get the value inside the database.
	 */
	public get(key: string): Promise<any> {
		return this.storageService.get(
			this.documentDatabase,
			documentInfoConfig.encryption,
			key
		);
	}

	/**
	 * Method to set a document inside database.
	 * @param key Primary key to be stored inside the database.
	 * @param value Value to be stored inside the database
	 */
	public async set(key: string, value: any): Promise<any> {
		const roleValue = { ...value, role: await this.authService.getUserRole() };
		return this.storageService.set(
			this.documentDatabase,
			documentInfoConfig.encryption,
			key,
			roleValue
		);
	}

	/**
	 * Method to get all the documents from the database.
	 */
	public getAll(): Promise<any> {
		return this.storageService.getAll(
			this.documentDatabase,
			documentInfoConfig.encryption
		);
	}

	/**
	 * Method to get all the documents with keys from the database.
	 */
	public getAllWithKeys(): Promise<any> {
		return this.storageService.getAllWithKeys(
			this.documentDatabase,
			documentInfoConfig.encryption
		);
	}
	/**
	 * Delete the document of the key provided by parameter.
	 * @param key Primary key to delete the value.
	 */
	public remove(key: string) {
		return this.storageService.remove(this.documentDatabase, key);
	}

	/**
	 * Delete all entries on the document database.
	 */
	public removeAll() {
		return this.storageService.removeAll(this.documentDatabase);
	}
}
