/**
 * Constants for the connection with Auth0.
 */
export const AUTH0_CONSTANTS = {
	SCOPE: 'openid profile email offline_access',
	PROMPT: 'login',
	SIGNUP_UP: 'sup',
	BASE_ROLE: 'btin-gen-base',
	BASE_TOKEN:
		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwczovL3NpZHJpdmVpcXR3aW4tcm9sZXMiOlsiYnRpbi1nZW4tYmFzZSJdLCJpc3MiOiJhcHBjbGllbnQiLCJpYXQiOjE1OTA5NjI0MDB9.rqQXRXx5cWqV7dnPbCmDnO2Dt2PThZze4XF2QDhTddc'
};
