import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { AuthService } from '@core/services/auth/auth.service';
import { API_CONSTANTS } from '@core/constants/api.constants';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
	private refreshTokenUri: string;

	constructor(private authService: AuthService) {
		this.refreshTokenUri = `https://${environment.appInfo.domain}/oauth/token`;
	}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		/**
		 * Excluded the refresh token request from the http interceptor
		 * Refresh token request is just being passed to the next handler and no further refresh token requests are being sent as part of this
		 */
		if (req.url.indexOf(this.refreshTokenUri) !== -1) {
			return next.handle(req);
		}

		return from(this.authService.getTokenUser()).pipe(
			mergeMap((token: string) => {
				let request: HttpRequest<any>;
				if (token) {
					request = req.clone({
						setHeaders: {
							[API_CONSTANTS.HEADER_TOKEN]: token
						}
					});
				}
				return next.handle(request || req);
			})
		);
	}
}
