import { ApiError } from './api-error';

/**
 * ApiError class for when too many requests are done.
 */
export class ApiErrorExceededRequests extends ApiError {
	/**
	 * Message
	 */
	public message: string;

	/**
	 * Reset time
	 */
	public resetTime: number;

	/**
	 * ApiError constructor.
	 * @param originalError Error coming directly from server
	 */
	constructor(public originalError: any) {
		super(originalError);
		this.message = this.originalError.message;
		this.resetTime = this.originalError.resetTime;
	}
}
