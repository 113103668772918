// Basic
import { Injectable, InjectionToken, Inject } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

// Config
import { MediaConfig } from '@config/media.config';

// Constants
import { MEDIA_BREAKPOINTS } from './media.constants';
import { map, distinctUntilChanged, share } from 'rxjs/operators';

/**
 * export the token to get the reference externally.
 */
export const MEDIA_CONFIG_TOKEN = new InjectionToken('_mediaConfigToken');

/**
 * Service used to know the current breakpoint of the application in the business context.
 */
@Injectable({ providedIn: 'root' })
export class MediaService {
	/**
	 * Observable with the current breakpoint on the application.
	 */
	private currentViewMedia: BehaviorSubject<string>;

	/**
	 * Observable boolean, if the application is on the LG breakpoint, this variable evaluate true.
	 */
	private isLgObservable: BehaviorSubject<boolean>;

	/**
	 * Observable boolean, if the application is on the MD breakpoint, this variable evaluate true.
	 */
	private isMdObservable: BehaviorSubject<boolean>;

	/**
	 * Observable boolean, if the application is on the XS breakpoint, this variable evaluate true.
	 */
	private isXsObservable: BehaviorSubject<boolean>;

	/**
	 * Constructor to declare all the necesary to initialize the class.
	 * @param media media breakpoints configuration
	 */
	constructor(@Inject(MEDIA_CONFIG_TOKEN) private media: MediaConfig) {
		this.isLgObservable = new BehaviorSubject<boolean>(false);
		this.isMdObservable = new BehaviorSubject<boolean>(false);
		this.isXsObservable = new BehaviorSubject<boolean>(false);
		this.media = media;
		this.currentViewMedia = new BehaviorSubject<string>(
			this._calculateMedia(window.screen.width)
		);
		// Observe the window.resize in order to calculate the change of the webview
		fromEvent(window, 'resize')
			.pipe(
				map((ev: any) => ev.currentTarget.innerWidth),
				map(width => this._calculateMedia(width)),
				distinctUntilChanged()
			)
			.subscribe(value => {
				this.currentViewMedia.next(value);
			});
		// Set new value from the event resize
		this.currentMedia().subscribe((mediaValue: string) => {
			this.isXsObservable.next(mediaValue === MEDIA_BREAKPOINTS.XS);
			this.isMdObservable.next(mediaValue === MEDIA_BREAKPOINTS.MD);
			this.isLgObservable.next(mediaValue === MEDIA_BREAKPOINTS.LG);
		});
	}

	/**
	 * Method used to return the observable of current breakpoint in the application.
	 */
	public currentMedia(): Observable<string> {
		return this.currentViewMedia.asObservable().pipe(share());
	}

	/**
	 * Method that return the observable for the current breakpoint, true if the application is MD.
	 */
	public isMd(): Observable<boolean> {
		return this.isMdObservable.asObservable().pipe(share());
	}

	/**
	 * Method that return the observable for the current breakpoint, true if the application is LG.
	 */
	public isLg(): Observable<boolean> {
		return this.isLgObservable.asObservable().pipe(share());
	}

	/**
	 * Method that return the observable for the current breakpoint, true if the application is LG.
	 */
	public isXs(): Observable<boolean> {
		return this.isXsObservable.asObservable().pipe(share());
	}

	/**
	 * Method used to calculate the current breakpoint of the application.
	 * @param width Current width of the application.
	 */
	private _calculateMedia(width: number): string {
		if (this.media.LG < width) {
			return MEDIA_BREAKPOINTS.LG;
		}
		if (this.media.MD < width) {
			return MEDIA_BREAKPOINTS.MD;
		}
		return MEDIA_BREAKPOINTS.XS;
	}
}
