import { createFeatureSelector, createSelector } from '@ngrx/store';
import { documentFeatureKey, DocumentState } from './document.reducer';

export const getDocumentState =
	createFeatureSelector<DocumentState>(documentFeatureKey);

export const getDocument = createSelector(
	getDocumentState,
	(state: DocumentState) => state.documents
);
