// Ionic Native
import { InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';

/**
 * Constant with the inAppBrowser plugin configuration.
 */
export const defaultInAppBrowserOptions: InAppBrowserOptions = {
  location: 'yes',
  clearcache: 'yes',
  footer: 'yes',
  disallowoverscroll: 'no',
  keyboardDisplayRequiresUserAction: 'yes'
};
