// Basic
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

/**
 * Service to handle the back button behavior.
 * Description: For switching from view A to view B, make sure to unregisterBack in ionViewWillLeave for view A
 * and registerNewBack in ionViewDidEnter for view B
 */
@Injectable({ providedIn: 'root' })
export class BackButtonService {
	/**
	 * Base priority for standard back button registrations
	 */
	private basePriority: number;

	/**
	 * Boolean to know if the back button is needed.
	 */
	private isBackButtonNeeded: boolean;

	/**
	 * Construtor where we import all needed in the service.
	 * @param platform Used to get information about your current device.
	 */
	constructor(private platform: Platform) {
		this.basePriority = 1;
		this.isBackButtonNeeded = this.platform.is('android'); // For the moment only android has backbutton
	}

	/**
	 * Method to register a new action for the hardware back button.
	 * Each time we register a new one, the priorityLevel increases, so the next time user presses back button, it will execute the last registered action.
	 * @param newAction Function to execute when user presses hardware back button.
	 */
	public registerNewBack(newAction, optionalPriority?: number) {
		if (this.isBackButtonNeeded) {
			const priority = optionalPriority ? optionalPriority : this.basePriority;
			const callbackUnregister = this.platform.backButton.subscribeWithPriority(
				priority,
				() => {
					this._unregisterBack(callbackUnregister);
					newAction();
				}
			);
			return callbackUnregister;
		}
		return undefined;
	}

	/**
	 * Method to launch the same private method from an outside call
	 * @param unregisterAction returned function of the registered action
	 */
	public unregisterBack(unregisterAction: Subscription): Promise<any> {
		return this._unregisterBack(unregisterAction);
	}

	/**
	 * Method to unregister a specific registered action for the hardware back button
	 * @param unregisterAction returned function of the registered action
	 */
	private _unregisterBack(unregisterAction: Subscription): Promise<any> {
		return new Promise<any>(resolve => {
			if (unregisterAction) {
				unregisterAction.unsubscribe();
			}
			resolve(undefined);
		});
	}
}
