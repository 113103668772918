import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import {
	MachineActionTypes,
	saveMachineVersion,
	requestMachineError,
	requestMachineVersionHistory,
	requestMachineVersionHistoryFromDB,
	navigateToMachineDetails,
	saveOfflineMachineIntoDB,
	deleteMachineLocationFromDB,
	requestMachineLocation
} from '../machine.actions';
import { addFavoriteLocation } from '@modules/favorites/actions';
import { MachineService } from '../../infrastructure/service/machine.service';
import { from } from 'rxjs';
import { updateRecentlyStatus } from '@modules/recently/actions';
import { SynchroModel } from '@modules/recently/core/model/recently';
import { RecentlyService } from '@modules/recently/infrastructure/service/recently.service';
import { Machine } from '../../core/model/machine';
import { dismissLoading } from '@modules/app/actions';
import { DbErrorNotFound } from '@core/error/db/db-error.module';
import { ExtractMachineInfoService } from '@modules/machine/core/service/extract-machine-info.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class MachineEffects {
	getMachineLatestVersion$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MachineActionTypes.requestMachineLatestVersion),
			mergeMap((action: any) =>
				this.machineService
					.getLatestVersion(
						action.serialnumber,
						action.manufacturerproductnumber
					)
					.pipe(
						mergeMap((version: any) => [
							this.saveMachineLatestVersion(version, action),
							requestMachineVersionHistory({
								serialnumber: action.serialnumber,
								manufacturerproductnumber: action.manufacturerproductnumber
							}),
							requestMachineLocation({ serialnumber: action.serialnumber }),
							addFavoriteLocation({ serialnumber: action.serialnumber }),
							this.updateRecentlyStatus(action.serialnumber, SynchroModel.OK),
							navigateToMachineDetails({
								serialnumber: action.serialnumber,
								manufacturerproductnumber: action.manufacturerproductnumber
							})
						]),
						catchError(error => {
							return from([
								requestMachineError({
									serialnumber: action.serialnumber,
									manufacturerproductnumber: action.manufacturerproductnumber,
									error
								}),
								this.updateRecentlyStatus(
									action.serialnumber,
									SynchroModel.NOT_FOUND
								),
								deleteMachineLocationFromDB({
									serialnumber: action.serialnumber
								})
							]);
						})
					)
			)
		);
	});

	getMachineLatestVersionFromDB$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MachineActionTypes.requestMachineLatestVersionFromDB),
			mergeMap((action: any) =>
				this.machineService
					.getLatestVersionFromDB(
						action.serialnumber,
						action.manufacturerproductnumber
					)
					.pipe(
						mergeMap((version: any) => [
							saveMachineVersion({ machine: version }),
							requestMachineVersionHistoryFromDB({
								serialnumber: action.serialnumber,
								manufacturerproductnumber: action.manufacturerproductnumber
							}),
							requestMachineLocation({ serialnumber: action.serialnumber }),
							addFavoriteLocation({ serialnumber: action.serialnumber }),
							this.updateRecentlyStatus(action.serialnumber, SynchroModel.OK),
							navigateToMachineDetails({
								serialnumber: action.serialnumber,
								manufacturerproductnumber: action.manufacturerproductnumber
							})
						]),
						catchError(() => {
							return from([
								saveOfflineMachineIntoDB({
									serialnumber: action.serialnumber,
									machine: {
										serialnumber: action.serialnumber,
										manufacturerproductnumber: action.manufacturerproductnumber
									}
								}),
								requestMachineError({
									serialnumber: action.serialnumber,
									manufacturerproductnumber: action.manufacturerproductnumber,
									error: new DbErrorNotFound()
								}),
								this.updateRecentlyStatus(
									action.serialnumber,
									SynchroModel.FETCHING
								)
							]);
						})
					)
			)
		);
	});

	getMachineVersion$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MachineActionTypes.requestMachineVersion),
			mergeMap((action: any) =>
				this.machineService
					.getMachineVersion(
						action.serialnumber,
						action.manufacturerproductnumber,
						action.version
					)
					.pipe(
						mergeMap((version: any) => [
							this.saveMachineVersion(version, action),
							requestMachineLocation({ serialnumber: action.serialnumber })
						])
					)
			)
		);
	});

	getMachineVersionFromDB$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MachineActionTypes.requestMachineVersionFromDB),
			mergeMap((action: any) =>
				this.machineService
					.getMachineVersionFromDB(action.serialnumber, action.version)
					.pipe(
						mergeMap((machine: any) => [
							saveMachineVersion({ machine }),
							requestMachineLocation({ serialnumber: action.serialnumber })
						])
					)
			)
		);
	});

	navigateToMachineDetails$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MachineActionTypes.navigateToMachineDetails),
			map((action: any) => {
				this.machineService.openMachine(
					action.serialnumber,
					action.manufacturerproductnumber
				);
				return dismissLoading({});
			})
		);
	});

	showRequestMachineError$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MachineActionTypes.requestMachineError),
			map((action: any) => {
				this.machineService.showRequestMachineError(
					this.sanitizer.sanitize(1,action.serialnumber?.replace(/</g, '&lt;').replace(/>/g, '&gt;')),
					this.sanitizer.sanitize(1,action.manufacturerproductnumber?.replace(/</g, '&lt;').replace(/>/g, '&gt;')),
					action.error
				);
				return dismissLoading({});
			})
		);
	});

	private updateRecentlyStatus(
		serialNumber: string,
		synchroStatus: SynchroModel.SynchroStatus
	) {
		this.recentlyService.updateRecentlyStatus(serialNumber, synchroStatus);

		return updateRecentlyStatus({
			serialNumber: serialNumber,
			synchroStatus
		});
	}

	private saveMachineLatestVersion(version: any, action: any) {
		return this.saveMachineVersion(version, action, true);
	}

	private saveMachineVersion(version: any, action: any, isLatest?: boolean) {
		const machine: Machine = this.extractMachineInfoService.extractMachineInfo(
			version,
			action
		);
		this.machineService.saveMachineIntoDB(machine, isLatest);

		return saveMachineVersion({ machine });
	}

	constructor(
		private recentlyService: RecentlyService,
		private actions$: Actions,
		private machineService: MachineService,
		private extractMachineInfoService: ExtractMachineInfoService,
		private sanitizer: DomSanitizer
	) {}
}
