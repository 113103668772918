import * as FavoritesActions from './state/favorites.actions';
import * as FavoritesSelectors from './state/favorites.selectors';
import { FavoritesModule } from './favorites.module';
import { FavoritesState } from './state/favorites.reducer';

export {
	FavoritesActions,
	FavoritesSelectors,
	FavoritesState,
	FavoritesModule
};
