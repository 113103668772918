// Basic
import { StorageConfig } from '@ionic/storage';

// Constants
import { DB_NAME } from '@core/constants/storage.constants';

/**
 * Configuraton for the storage service
 */
export const permissionConfig = {
  databaseConfig: {
    name: `${DB_NAME.NAME}_v${DB_NAME.VERSION}.${DB_NAME.SUFFIX}`,
    storeName: '_permission',
    driverOrder: ['indexeddb', 'sqlite', 'websql']
  } as StorageConfig,
  encryption: false
};
