import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import {
	RecentlyActionTypes,
	removeRecently,
	saveRecently,
	saveRecents
} from './recently.actions';
import { RecentlyService } from '../infrastructure/service/recently.service';
import { Recently } from '../core/model/recently';
import { forkJoin, from } from 'rxjs';
import { FavoritesService } from '@modules/favorites/infrastructure/service/favorites.service';
import { removeFavorite } from '@modules/favorites/actions';

@Injectable()
export class RecentlyEffects {
	getRecents$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(RecentlyActionTypes.requestRecents),
			mergeMap((action: any) =>
				from(this.recentlyService.getAllRecently()).pipe(
					mergeMap((recents: Recently[]) => {
						const newRecents = recents.map(async (recently: Recently) => {
							recently.isBookmarked = await this.recentlyService
								.isRecentlyBoormarked(recently)
								.catch(error => {
									return false;
								});
							return recently;
						});

						return forkJoin(newRecents).pipe(
							map(newRecents => {
								return newRecents.sort((recentlyA, recentlyB) => {
									const recentlyATime = new Date(
										recentlyA.lastSearched
									).getTime();
									const recentlyBTime = new Date(
										recentlyB.lastSearched
									).getTime();

									return recentlyBTime - recentlyATime;
								});
							})
						);
					}),
					map(recently => {
						return saveRecents({ recently });
					})
				)
			)
		);
	});

	saveRecently$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(RecentlyActionTypes.saveRecentlyIntoDB),
			mergeMap((action: any) => {
				const newRecently = {
					...action.recently,
					lastSearched: new Date()
				};

				return from(this.recentlyService.setRecently(newRecently)).pipe(
					map(() => {
						return saveRecently({ recently: newRecently });
					})
				);
			})
		);
	});

	deleteRecently$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(RecentlyActionTypes.deleteRecentlyFromDB),
			mergeMap((action: any) => this.removeActions(action.recently)),
			mergeMap(action => action)
		);
	});

	private async removeActions(recently: Recently) {
		const actionsArray = [];
		try {
			await this.recentlyService.remove(recently.serialNumber);
			actionsArray.push(removeRecently({ recently }));
		} catch (error) {}

		try {
			const favorite = await this.favoriteService.getFavorite(
				recently.serialNumber
			);
			this.favoriteService.removeFavorite(recently.serialNumber);
			actionsArray.push(removeFavorite({ favorite }));
		} catch (error) {}

		return actionsArray;
	}

	constructor(
		private actions$: Actions,
		private recentlyService: RecentlyService,
		private favoriteService: FavoritesService
	) {}
}
